<div class="login">
  <div class="fl_scrn">
    <div class="frm_cntnr">
      <div class="wrapper">
        <div class="logo_head">
          <img src="assets/images/logo-westream.svg" alt="we-stream logo" width="141" height="34">
        </div>
        <form [formGroup]="loginFormData">
          <div class="logo_form"></div>
          <div class="input-box">
            <mat-form-field appearance="legacy" class="full_wdth">
              <mat-label>Username</mat-label>
              <input matInput formControlName="userName" placeholder="Enter your username">
              <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
          </div>
          <div class="input-box">
            <mat-form-field appearance="legacy" class="full_wdth">
              <mat-label>Password</mat-label>
              <input matInput formControlName="passWord" [type]="hide ? 'password' : 'text'"
                placeholder="Enter your password">
              <button aria-label="button" type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon style="font-size: 20px;">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="recaptcha_field">
            <re-captcha (resolved)="resolved($event)" siteKey="6Lc6ng0pAAAAAB2ZyzoxByLl8CqKzstq7wfthLrp"></re-captcha>
          </div>
          <button aria-label="button" class="smt_btn cs-bg-primary" type="button" (click)="loginUser()"
          [disabled]="!isCaptchaResolved || !loginFormData.valid">
            Login
          </button>
        </form>
        <div class="alert alert-danger p-1 mt-4 rounded-1 text-center" role="alert" *ngIf="isError">
          Invalid Username or Password
        </div>
        <div class="loader d-flex justify-content-center align-items-center" 
        *ngIf="isLoading" style="margin-top: 20px;">
          <mat-progress-spinner [diameter]="40" mode="indeterminate"
          class="cs-color-primary"></mat-progress-spinner>
        </div>
      </div>
    </div>
  </div>
</div>