<app-loader *ngIf="!(distributors || resellers)"></app-loader>
<div class="container" *ngIf="distributors || resellers" style="max-width: 1000px;">
    <div class="row">
        <div class="col-md-5">
            <div class="row" *ngIf="renderResult || isAdmin">
                <header style="margin-bottom: 10px" class="header-buttons">
                    <button aria-label="button" class="btn btn-outline-primary btn-sm" *ngIf="isAdmin" data-bs-target="#distributorModel"
                        data-bs-toggle="modal" (click)="addDistributor('create')"
                        style="font-size: 11px;width: 140px;">Add Distributor</button>

                    <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="addReseller('create')"
                        data-bs-target="#resellerModel" data-bs-toggle="modal"
                        *ngIf="renderResult && (renderResult.role == ROLE_TYPE.Distributor)"
                        style="font-size: 11px;width: 120px;margin-left: 10px;">Add Reseller</button>

                    <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="addAccount('create')" data-bs-target="#accountModel"
                        data-bs-toggle="modal" *ngIf="renderResult && (renderResult.role == ROLE_TYPE.Reseller)"
                        style="font-size: 11px;width: 120px;margin-left: 10px;">Add Account</button>

                    <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="addUser('create')" data-bs-target="#userModel"
                        data-bs-toggle="modal" *ngIf="renderResult && (renderResult.role == ROLE_TYPE.Account)"
                        style="font-size: 11px;width: 100px;margin-left: 10px;">Add User</button>
                </header>
            </div>
            <div style="width: 100%;background-color:#F7F8F8;border-radius: 12px;">
                <div class="d-flex flex-column" style="font-size: 15px;color: black;
                    font-weight: 500;margin: 13px;padding: 10px;">
                        <div>
                            <b>{{userData.firstName + ' ' + userData.lastName}}</b>
                        </div>
                        <div class="cs-color-primary" style="font-size: 12px;">
                            <b>{{userData.roles[0]}}</b>
                        </div>
                    </div>
            </div>
            <!-- For Distributor and Admin Login -->
            <mat-accordion *ngIf="isDistributor || isAdmin">
                <mat-expansion-panel *ngFor="let distributor of distributors; let first = first"
                    style="background-color:#F7F8F8" [expanded]="first">
                    <mat-expansion-panel-header (click)="renderData('distributor',distributor)">
                        <div class="d-flex flex-column" style="font-size: 15px;color: black;
                        font-weight: 500;">
                            <div>
                                <b>{{ distributor.companyName }}</b>
                            </div>
                            <div class="cs-color-primary" style="font-size: 12px;">
                                <b>({{distributor.role}})</b>
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let reseller of distributor.resellers"
                            style="background-color:#F7F8F8">
                            <mat-expansion-panel-header (click)="renderData('reseller',reseller)">
                                <div class="d-flex flex-column" style="font-size: 15px;color: black;
                                font-weight: 500;">
                                    <div>
                                        <b>{{ reseller.companyName }}</b>
                                    </div>
                                    <div class="cs-color-primary" style="font-size: 12px;">
                                        <b>({{reseller.role}})</b>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let account of reseller.accounts" style="background-color:#F7F8F8">
                                    <mat-expansion-panel-header (click)="renderData('account',account)"
                                        style="background-color:#F7F8F8">
                                        <div class="d-flex flex-column" style="font-size: 15px;color: black;
                                        font-weight: 500;">
                                            <div>
                                                <b>{{ account.companyName }}</b>
                                            </div>
                                            <div class="cs-color-primary" style="font-size: 12px;">
                                            <b>({{account.role}})</b>
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <mat-accordion>
                                        <mat-expansion-panel *ngFor="let endUser of account.endUsers" style="background-color:#F7F8F8">
                                            <mat-expansion-panel-header (click)="renderData('user',endUser)"
                                                style="background-color:#F7F8F8">
                                                <div class="d-flex flex-column" style="font-size: 15px;color: black;
                                                font-weight: 500;">
                                                    <div>
                                                    <b>{{ endUser.companyName }}</b>
                                                    </div>
                                                    <div class="cs-color-primary" style="font-size: 12px;">
                                                    <b>({{endUser.role}})</b>
                                                    </div>
                                                </div>
                                            </mat-expansion-panel-header>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- End Distributor Login -->
            <!-- For Reseller Login -->
            <mat-accordion *ngIf="isReseller">
                <mat-expansion-panel *ngFor="let reseller of resellers" style="background-color:#F7F8F8">
                    <mat-expansion-panel-header (click)="renderData('reseller',reseller)">
                        <div class="d-flex flex-column" style="font-size: 15px;color: black;
                        font-weight: 500;">
                            <div>
                            <b>{{ reseller.companyName }}</b>
                            </div>
                            <div class="cs-color-primary" style="font-size: 12px;">
                            <b>({{reseller.role}})</b>
                            </div>
                        </div>
                    </mat-expansion-panel-header>
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let account of reseller.accounts" style="background-color:#F7F8F8">
                            <mat-expansion-panel-header (click)="renderData('account',account)"
                                style="background-color:#F7F8F8">
                                <div class="d-flex flex-column" style="font-size: 15px;color: black;
                                font-weight: 500;">
                                    <div>
                                    <b>{{ account.companyName }}</b>
                                    </div>
                                    <div class="cs-color-primary" style="font-size: 12px;">
                                    <b>({{account.role}})</b>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let endUser of account.endUsers" style="background-color:#F7F8F8">
                                    <mat-expansion-panel-header (click)="renderData('user',endUser)"
                                        style="background-color:#F7F8F8">
                                        <div class="d-flex flex-column" style="font-size: 15px;color: black;
                                        font-weight: 500;">
                                            <div>
                                            <b>{{ endUser.companyName }}</b>
                                            </div>
                                            <div class="cs-color-primary" style="font-size: 12px;">
                                            <b>({{endUser.role}})</b>
                                            </div>
                                        </div>
                                    </mat-expansion-panel-header>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- End Reseller Login -->
        </div>
        <div class="col-md-7">
            <div class="container display_container" [style.height]="tableContainerHeight"
                [style.max-height]="tableContainerHeight">
                <div class="row" *ngIf="renderResult || isAdmin">
                    <header class="d-flex align-center display_container-buttons p-1">
                        <input type="file" accept=".csv" #fileInput (change)="onFileSelected($event)"
                            style="display: none;">
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" *ngIf="renderResult" (click)="fileInput.click()" style="font-size: 11px;width: 210px;">Assign Devices From CSV
                            File</button>
                        <!-- <button aria-label="button" class="btn btn-outline-primary btn-sm" *ngIf="renderResult" data-bs-toggle="modal"
                            data-bs-target="#addDeviceByRangeModel" style="font-size: 11px;width: 180px;">Assign Devices By
                            Range</button> -->
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="addDistributor('edit',renderResult)"
                            *ngIf="renderResult.role == ROLE_TYPE.Distributor" data-bs-toggle="modal"
                            data-bs-target="#distributorModel" style="font-size: 11px;width: 50px;">Edit</button>
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="addReseller('edit',renderResult)"
                            *ngIf="renderResult.role == ROLE_TYPE.Reseller" data-bs-toggle="modal"
                            data-bs-target="#resellerModel" style="font-size: 11px;width: 50px;">Edit</button>
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="addAccount('edit',renderResult)"
                            *ngIf="renderResult.role == ROLE_TYPE.Account" data-bs-toggle="modal"
                            data-bs-target="#accountModel" style="font-size: 11px;width: 50px;">Edit</button>
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="addUser('edit',renderResult)"
                            *ngIf="renderResult.role == ROLE_TYPE.User" data-bs-toggle="modal"
                            data-bs-target="#userModel" style="font-size: 11px;width: 50px;">Edit</button>
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" data-bs-target="#topUpModel"
                            data-bs-toggle="modal"
                            *ngIf="isAdmin && renderResult && ((renderResult.role == ROLE_TYPE.Reseller) || (renderResult.role == ROLE_TYPE.Distributor))"
                            style="font-size: 11px;width: 60px;">Top up</button>
                    </header>
                </div>
                <div class="row" *ngIf="renderResult" style="margin-top: 5px;">
                    <div class="col-md-7 d-flex flex-row align-center">
                        <div class="col-md-1">
                            <mat-icon style="color:#454545;font-size:22px;margin: 5px;">person</mat-icon>
                        </div>
                        <div class="col-md-11 justify-content-start" style="margin: 5px;
                        font-weight: 500;margin-left: 15px;">
                            <b>{{renderResult.companyName}}</b>
                        </div>
                    </div>
                    <div class="col-md-5 d-flex justify-content-end" style="margin-top:5px;color:#119FDB">
                        ({{renderResult.role}})
                    </div>
                </div>
                <table class="table">
                    <tbody *ngIf="renderResult">
                        <tr *ngIf="renderResult.vatNumber">
                            <th>Tax Identification:</th>
                            <td>{{ renderResult.vatNumber }}</td>
                        </tr>
                        <tr *ngIf="renderResult.commission">
                            <th>Commission:</th>
                            <td>{{ renderResult.commission }}</td>
                        </tr>
                        <tr *ngIf="renderResult.type">
                            <th>Type:</th>
                            <td>{{ typeMap[renderResult.type] }}</td>
                        </tr>
                        <tr>
                            <th>Country:</th>
                            <td>{{ renderResult.country }}</td>
                        </tr>
                        <tr>
                            <th>City:</th>
                            <td>{{ renderResult.city }}</td>
                        </tr>
                        <tr>
                            <th>Postal Code:</th>
                            <td>{{ renderResult.postalCode }}</td>
                        </tr>
                        <tr>
                            <th>Address:</th>
                            <td>{{ renderResult.address1 }}</td>
                        </tr>
                        <tr *ngIf="renderResult.address2">
                            <th>Address 2:</th>
                            <td>{{ renderResult.address2 }}</td>
                        </tr>
                        <tr>
                            <th>Contact Person:</th>
                            <td>{{ renderResult.contactPerson }}</td>
                        </tr>
                        <tr>
                            <th>Email:</th>
                            <td>{{ renderResult.email }}</td>
                        </tr>
                        <tr>
                            <th>Bank Country:</th>
                            <td>{{ renderResult.bankCountry }}</td>
                        </tr>
                        <tr *ngIf="renderResult.additionalBankInfo">
                            <th>Bank Information:</th>
                            <td>{{ renderResult.additionalBankInfo }}</td>
                        </tr>
                        <tr *ngIf="isAdmin && renderResult.balance">
                            <th>Current Balance:</th>
                            <td>{{ renderResult.balance }} EUR</td>
                        </tr>
                        <tr>
                            <th>Assigned Devices:</th>
                            <td>{{ renderResult.devices.length > 0 ? renderResult.devices.length : 0}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-center align-center" style="margin: 0.5rem;"
                    *ngIf="dataSource.data.length > 0">
                    <span style="font-family:Montserrat, sans-serif;"><b>IMEI Range</b></span>
                </div>
                <div style="overflow: auto;" [style.height]="imeiTableHeight" *ngIf="dataSource.data.length > 0">
                    <table mat-table [dataSource]="dataSource" class="imeiTable">
                        <ng-container matColumnDef="from">
                            <th mat-header-cell *matHeaderCellDef style="font-family:Montserrat, sans-serif;"><b>From</b></th>
                            <td mat-cell *matCellDef="let element" style="font-family:Montserrat, sans-serif;"><b>{{element.from}}</b></td>
                        </ng-container>

                        <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef style="font-family:Montserrat, sans-serif;"><b>To</b></th>
                            <td mat-cell *matCellDef="let element" style="font-family:Montserrat, sans-serif;"><b>{{element.to}}</b></td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Distributor Modal -->
<div class="modal fade" id="distributorModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="distributorForm" novalidate class="settingsForm">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">{{distributorHeaderText}}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Company Name" formControlName="companyName"
                            required minlength="1">
                    </div>
                    <div class="d-flex" style="margin: 0rem !important;">
                        <div>
                            <input class="form-control" type="text" placeholder="Tax Identification"
                                formControlName="taxIdnf" required minlength="1">
                        </div>
                        <div>
                            <input class="form-control" type="text" placeholder="Commission (%)"
                                formControlName="comission" required pattern="[0-9]+" maxlength="2">
                        </div>
                    </div>
                    <div class="d-flex" style="margin: 0rem !important;">
                        <div>
                            <input class="form-control" type="text" placeholder="Country" formControlName="country"
                                required minlength="1">
                        </div>
                        <div>
                            <input class="form-control" type="text" placeholder="City" formControlName="city" required
                                minlength="1">
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Postal Code" formControlName="postalCode"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 1" formControlName="addressOne"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 2 (Optional)"
                            formControlName="addressTwo" minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Contact Person"
                            formControlName="contactPerson" required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Email" formControlName="email" required
                            minlength="3">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Bank Country" formControlName="bankCountry"
                            required minlength="2">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Additional Bank Info (Optional)"
                            formControlName="bankInfo" minlength="3">
                    </div>
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="submit" class="btn btn-outline-success" data-bs-dismiss="modal"
                        [disabled]="!distributorForm.valid"
                        (click)="postDistributor()">{{distributorButtonText}}</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                        (click)="clearForm()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>


<!-- Reseller Modal -->
<div class="modal fade" id="resellerModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="resellerForm" novalidate class="settingsForm">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">{{resellerHeaderText}}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Company Name" formControlName="companyName"
                            required minlength="1">
                    </div>
                    <div class="d-flex" style="margin: 0rem !important;">
                        <div>
                            <input class="form-control" type="text" placeholder="Tax Identification"
                                formControlName="taxIdnf" required minlength="1">
                        </div>
                        <div style="width: 50%;">
                            <select class="form-control" formControlName="type" required>
                                <option value="" disabled selected>Select Type</option>
                                <option value="0">Platinum</option>
                                <option value="1">Gold</option>
                                <option value="2">Silver</option>
                                <option value="3">Bronze</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex" style="margin: 0rem !important;">
                        <div>
                            <input class="form-control" type="text" placeholder="Country" formControlName="country"
                                required minlength="1">
                        </div>
                        <div>
                            <input class="form-control" type="text" placeholder="City" formControlName="city" required
                                minlength="1">
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Postal Code" formControlName="postalCode"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 1" formControlName="addressOne"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 2 (Optional)"
                            formControlName="addressTwo" minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Contact Person"
                            formControlName="contactPerson" required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Email" formControlName="email" required
                            minlength="3">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Bank Country" formControlName="bankCountry"
                            required minlength="2">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Additional Bank Info (Optional)"
                            formControlName="bankInfo" minlength="3">
                    </div>
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="submit" class="btn btn-outline-success" data-bs-dismiss="modal"
                        [disabled]="!resellerForm.valid" (click)="postReseller()">{{resellerButtonText}}</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                        (click)="clearForm()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>


<!-- Account Modal -->
<div class="modal fade" id="accountModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="accountForm" novalidate class="settingsForm">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">{{accountHeaderText}}</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Company Name" formControlName="companyName"
                            required minlength="1">
                    </div>
                    <div>
                        <input class="form-control" type="text" placeholder="Tax Identification"
                            formControlName="taxIdnf" required minlength="1">
                    </div>
                    <div class="d-flex" style="margin: 0rem !important;">
                        <div>
                            <input class="form-control" type="text" placeholder="Country" formControlName="country"
                                required minlength="1">
                        </div>
                        <div>
                            <input class="form-control" type="text" placeholder="City" formControlName="city" required
                                minlength="1">
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Postal Code" formControlName="postalCode"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 1" formControlName="addressOne"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 2 (Optional)"
                            formControlName="addressTwo" minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Contact Person"
                            formControlName="contactPerson" required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Email" formControlName="email" required
                            minlength="3">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Bank Country" formControlName="bankCountry"
                            required minlength="2">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Additional Bank Info (Optional)"
                            formControlName="bankInfo" minlength="3">
                    </div>
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="submit" class="btn btn-outline-success" data-bs-dismiss="modal"
                        [disabled]="!accountForm.valid" (click)="postAccount()">{{accountButtonText}}</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                        (click)="clearForm()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- User Modal -->
<div class="modal fade" id="userModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="userForm" novalidate class="settingsForm">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">{{userHeaderText}}</h5>
                </div>
                <div>
                    <div class="radio-buttons">
                        <label>
                            <input type="radio" name="userType" (click)="selectedUserType = 0" value="Private"
                                [checked]="selectedUserType === 0">
                            Private
                        </label>
                        <label>
                            <input type="radio" name="userType" (click)="selectedUserType = 1" value="Business"
                                [checked]="selectedUserType === 1">
                            Business
                        </label>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="form-group" *ngIf="selectedUserType == 1">
                        <input class="form-control" type="text" placeholder="Company Name" formControlName="companyName"
                            required minlength="1">
                    </div>
                    <div *ngIf="selectedUserType == 1">
                        <input class="form-control" type="text" placeholder="Tax Identification"
                            formControlName="taxIdnf" required minlength="1">
                    </div>
                    <div *ngIf="selectedUserType == 0">
                        <input class="form-control" type="text" placeholder="Name" formControlName="companyName"
                            required minlength="1">
                    </div>
                    <div class="d-flex" style="margin: 0rem !important;">
                        <div>
                            <input class="form-control" type="text" placeholder="Country" formControlName="country"
                                required minlength="1">
                        </div>
                        <div>
                            <input class="form-control" type="text" placeholder="City" formControlName="city" required
                                minlength="1">
                        </div>
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Postal Code" formControlName="postalCode"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 1" formControlName="addressOne"
                            required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Address 2 (Optional)"
                            formControlName="addressTwo" minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Contact Person"
                            formControlName="contactPerson" required minlength="1">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Email" formControlName="email" required
                            minlength="3">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Bank Country" formControlName="bankCountry"
                            required minlength="2">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="Additional Bank Info (Optional)"
                            formControlName="bankInfo" minlength="3">
                    </div>
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="submit" class="btn btn-outline-success" data-bs-dismiss="modal"
                        (click)="postUser()">{{userButtonText}}</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                        (click)="clearForm()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Top Up Balance Modal -->
<div class="modal fade" id="topUpModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form novalidate class="settingsForm">
                <div class="modal-header">
                    <h5 class="modal-title text-primary">TOP UP ACCOUNT BALANCE</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input class="form-control" type="number" placeholder="Amount in EUR" [value]="balanceAmount"
                            (input)="handleAccountBalance($event)" required>
                    </div>
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="submit" class="btn btn-outline-success" data-bs-dismiss="modal"
                        [disabled]="balanceAmount < 0 || balanceAmount == ''" (click)="postTopUp()">Top Up</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                        (click)="clearForm()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Add Device by range Modal -->
<div class="modal fade" id="addDeviceByRangeModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <form [formGroup]="deviceByRangeForm" novalidate class="settingsForm">
                <div class="modal-header">
                    <h5 class="modal-title text-primary" style="margin-left: 0.6rem;">Add Device By Range</h5>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="From IMEI" formControlName="imeiFrom"
                            required minlength="15" maxlength="15">
                    </div>
                    <div class="form-group">
                        <input class="form-control" type="text" placeholder="To IMEI" formControlName="imeiTo" required
                            minlength="15" maxlength="15">
                    </div>
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="submit" class="btn btn-outline-success" (click)="postDeviceRange()"
                        data-bs-dismiss="modal"
                        [disabled]="!deviceByRangeForm.valid && deviceByRangeForm.value.imeiFrom < deviceByRangeForm.value.imeiTo">Add</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                        (click)="clearForm()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>