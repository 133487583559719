import {TfaActionParameter} from './tfa-action-parameter';

export class TfaAction {
  id: String;
  actionName: String;
  parameters: TfaActionParameter[];
  parameterValues: {};

  constructor(id: number, actionName: String, parameters: TfaActionParameter[]) {
    this.id = id + '';
    this.actionName = actionName;
    this.parameters = parameters;
    this.parameterValues = {};
  }
}
