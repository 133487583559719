import { RoleType } from '../../_enums/role-type.enum';
import { IDistributor } from '../../_interfaces/distributor.interface';
import { IServerDistributor } from '../../_interfaces/server.distributor.interface';
import { Reseller } from './reseller';
import { Role } from './role';

export class Distributor extends Role implements IDistributor {

  commission: number;
  resellers: Reseller[] = [];

  constructor(data?: IServerDistributor) {
    super(data);
    this.role = RoleType.Distributor;
    if (!data) { return; }
    if (data.resellers && data.resellers.length) {
      for (let i = 0; i < data.resellers.length; i++) {
        this.resellers.push(new Reseller(data.resellers[i]));
      }
    }
    this.commission = data.commission;
  }

}
