<div class="container mt-4" style="max-width: 1000px;font-family:Montserrat, sans-serif;">
    <!-- Search bar and Add Device button -->
    <div class="row mb-3">
        <div class="col-md-10">
            <div class="input-group">
                <input style="font-size: 14px;border: 1px solid #727272;" class="form-control" type="text" placeholder="Search Users"
                    (keyup)="onSearch($event)">
                <span class="input-group-text" style="border: 1px solid #727272;background: #ffff;">
                    <mat-icon class="center-align" style="font-size: 23px;color:#727272;">search</mat-icon>
                </span>
            </div>
        </div>
        <div class="col-md-2">
            <button aria-label="button" type="button" class="center-align cs-bg-button" data-bs-toggle="modal"
                data-bs-target="#addDeviceModel">
                <mat-icon style="font-size: 17px;display: flex;align-items: center;">add_rounded</mat-icon>
                <span class="center-align" style="font-size: 14px;">Add Group</span>
            </button>
        </div>
    </div>

    <!-- List of device cards -->
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row" style="font-family:Montserrat, sans-serif;">
        <div class="col-md-3" *ngFor="let device of filterDeviceGroup">
            <mat-card class="mt-2" style="background-color: #F7F8F8;border-radius: 12px;height: 100px;">
                <mat-card-content>
                    <h5 style="font-weight: 600;">{{ device.name }}</h5>
                </mat-card-content>
                <mat-card-content>
                    <button aria-label="button" class="center-align btn btn-outline-primary btn-sm" data-bs-toggle="modal"
                        data-bs-target="#editDeviceModel" (click)="populateModel(device)">
                        <!-- <mat-icon style="font-size: 15px;display: flex;align-items: center;">edit</mat-icon> -->
                        <span class="center-align" style="font-size: 11px;font-family:Montserrat, sans-serif;">Edit</span>
                    </button>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <!-- Add Device Model -->
    <div class="modal fade" id="addDeviceModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">ADD DEVICE GROUP</h5>
                </div>
                <div class="modal-body">
                    <input class="form-control" type="text" placeholder="Enter Group name"
                        [(ngModel)]="newDeviceGroup" required minlength="1">
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="button" class="btn btn-outline-success" (click)="postNewGroup()"
                        data-bs-dismiss="modal" [disabled]="newDeviceGroup.length == 0">Create</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                        (click)="clearInput()">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Edit Device Model -->
    <div class="modal fade" id="editDeviceModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">EDIT DEVICE GROUP</h5>
                </div>
                <div class="modal-body">
                    <input class="form-control" type="text" [(ngModel)]="editDeviceGroup" required minlength="1">
                </div>
                <div class="modal-footer p-1">
                    <button aria-label="button" type="button" class="btn btn-outline-success" (click)="postEditGroup()"
                        data-bs-dismiss="modal" [disabled]="editDeviceGroup.length == 0">Update</button>
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>

</div>