<nav>
    <div class="row">
        <div class="">
            <div class="sidenav fix">
                <a class="user">
                    <span style="color: white;display: flex;text-align: center;margin-left: 5px;">{{ username }}</span>
                </a>
                <!--<a routerLink="./dashboard" class="menu-item" routerLinkActive="active">-->
                <!--<mat-icon>tachometer_alt</mat-icon>-->
                <!--<span> Dashboard</span>-->
                <!--</a>-->
                <a routerLink="./network" *ngIf="isDistributor || isAdmin" class="menu-item" routerLinkActive="active">
                    <mat-icon>public</mat-icon>
                    <span> My Network</span>
                </a>
                <a routerLink="./accounts" *ngIf="isReseller" class="menu-item" routerLinkActive="active">
                    <mat-icon>account_balance</mat-icon>
                    <span> My Accounts</span>
                </a>
                <a routerLink="./devices" class="menu-item" routerLinkActive="active">
                    <mat-icon>devices</mat-icon>
                    <span>My Devices</span>
                </a>
                <a routerLink="./balance" *ngIf="(isDistributor || isReseller)" class="menu-item"
                    routerLinkActive="active">
                    <mat-icon>euro_sign</mat-icon>
                    <span> My Balance</span>
                </a>
                <a routerLink="./settings/users" class="menu-item" routerLinkActive="active">
                    <mat-icon>settings</mat-icon>
                    <span> Settings</span>
                </a>
                <a routerLink="./settings/ars" *ngIf="isAdmin" class="menu-item" routerLinkActive="active">
                    <mat-icon>settings_input_component</mat-icon>
                    <span>TFA Settings</span>
                </a>
                <a routerLink="./settings/bundles" *ngIf="isAdmin" class="menu-item" routerLinkActive="active">
                    <mat-icon>rss_feed</mat-icon>
                    <span>Bundles Settings</span>
                </a>
                <a routerLink="./settings/device-groups" *ngIf="isAdmin" class="menu-item" routerLinkActive="active">
                    <mat-icon>dvr</mat-icon>
                    <span>Device Groups</span>
                </a>
                <a routerLink="./reports" *ngIf="isAdmin" class="menu-item" routerLinkActive="active">
                    <mat-icon>search</mat-icon>
                    <span>Reports</span>
                </a>
                <!--<a routerLink="./support" class="menu-item" routerLinkActive="active">-->
                <!--<mat-icon>briefcase</mat-icon>-->
                <!--<span> Support</span>-->
                <!--</a>-->
                <a class="menu-item" style="position: absolute;bottom: 0;width: 90%;" (click)="logout()">
                    <mat-icon style="color: white;">exit_to_app</mat-icon>
                    <span class="logout"> Logout</span>
                </a>
            </div>
        </div>
    </div>
</nav>
<main>
    <router-outlet></router-outlet>
</main>
<footer>
    <img src="assets/images/logo-westream.svg" alt="we-stream logo" width="100" height="25">
</footer>