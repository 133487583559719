import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true
  isError = false;
  timeoutId: any;
  isLoading:boolean = false
  isCaptchaResolved = false
  constructor(private formBuilder: FormBuilder, private router: Router, private auth: AuthenticationService) { }

  loginFormData = this.formBuilder.group({
    userName: ['',Validators.required],
    passWord: ['',Validators.required],
  })

  ngOnInit(): void {
    this.isCaptchaResolved = false
    const jwtToken = localStorage.getItem('jwt_token');
    const userInfo = localStorage.getItem('user_info');

    if (jwtToken !== null && userInfo !== null && navigator.onLine) {
      this.isLoading = false
      this.router.navigate(['my/settings/users']);
    }
  }

  loginUser() {
    this.isLoading = true
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.auth.login({ username: this.loginFormData.value.userName, password: this.loginFormData.value.passWord }).subscribe(res => {
      localStorage.setItem('jwt_token', res.data)
      this.auth.userInfo(res?.data).subscribe(res => {
        localStorage.setItem('user_info', JSON.stringify(res?.data))
        this.isLoading = false 
        this.router.navigate(['my/settings/users'])
      },(error)=>{})
    }, (error) => {
      this.isLoading = false
      this.isError = true
      this.timeoutId = setTimeout(()=>{
        this.isError = false
      },5000)
    })
  }

  resolved(captchaResponse: string){
    this.isCaptchaResolved = true
  }

}
