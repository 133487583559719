import { IDevice } from '../../_interfaces/device.interface';
import { IServerAccount } from '../../_interfaces/server.account.interface';
import { IServerDistributor } from '../../_interfaces/server.distributor.interface';
import { IServerReseller } from '../../_interfaces/server.reseller.interface';
import { IServerRole } from '../../_interfaces/server.role.interface';
import {IServerDevice} from '../../_interfaces/server.device.interface';

export class Device implements IDevice {
  id: number;
  imei: string;
  account: IServerAccount;
  distributor: IServerDistributor;
  endUser: IServerRole;
  reseller: IServerReseller;
  isRentalDevice: Boolean;
  isCorporateDevice: Boolean;
  name: string;
  companyName: string;
  deviceGroupId: number;
  deviceGroupName: string;

  public constructor(id: number, imei: string, name: string, companyName: string, deviceGroupId: number, deviceGroupName: string, endUser: IServerRole,
                     account: IServerAccount, reseller: IServerReseller, distributor: IServerDistributor, isRentalDevice: Boolean,
                     isCorporateDevice: Boolean) {
    this.id = id;
    this.imei = imei;
    this.name = name;
    this.companyName = companyName;
    this.endUser = endUser;
    this.account = account;
    this.reseller = reseller;
    this.distributor = distributor;
    this.isRentalDevice = isRentalDevice;
    this.isCorporateDevice = isCorporateDevice;
    this.deviceGroupId = deviceGroupId;
    this.deviceGroupName = deviceGroupName;
  }
  getDTO(): IDevice {
    throw new Error('Method not implemented.');
  }


  static getDummy(): Device {
    return new Device(undefined, undefined, undefined, undefined, undefined, undefined, undefined,
      undefined, undefined, undefined, false, false);
  }

  static buildDevice(device: IServerDevice): Device {
    return new Device(device.id, device.imei, device.name, device.companyName, device.deviceGroupId,
      device.imeiGroup && device.imeiGroup.name,
      device.endUser, device.account, device.reseller, device.distributor, device.isRental, device.isCorporate);
  }
}
