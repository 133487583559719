import { RoleType } from '../../_enums/role-type.enum';
import { IServerAccount } from '../../_interfaces/server.account.interface';
import { Role } from './role';
import { User } from './user';

export class Account extends Role {
    endUsers: Role[] = [];
    resellerId: number;
    distributorId: number;
    constructor(data?: IServerAccount) {
      super(data);
      this.role = RoleType.Account;
      if (!data) { return; }
      if (data.endUsers && data.endUsers.length) {
        for (let i = 0; i < data.endUsers.length; i++) {
          this.endUsers.push(new User(data.endUsers[i]));
        }
      }
    }
}
