import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { NetworkComponent } from './dashboard/network/network.component';
import { DevicesComponent } from './dashboard/devices/devices.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ReportsComponent } from './dashboard/reports/reports.component';
import { LoaderComponent } from './dashboard/loader/loader.component';
import {MatCardModule} from '@angular/material/card'
import {MatDialogModule} from '@angular/material/dialog'
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { SettingsDeviceGroupsComponent } from './dashboard/settings-device-groups/settings-device-groups.component';
import { SettingsBundlesComponent } from './dashboard/settings-bundles/settings-bundles.component';
import { SettingsUsersComponent } from './dashboard/settings-users/settings-users.component';
import { BalanceComponent } from './dashboard/balance/balance.component';
import { SettingsArsComponent } from './dashboard/settings-ars/settings-ars.component';
import { TfaChainService } from './dashboard/settings-ars/tfa-chain.service';
import { MessageService } from './dashboard/messages/message.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AlertService } from './dashboard/alerts/alert.service';

@NgModule({
  declarations: [
    DashboardComponent,
    NetworkComponent,
    DevicesComponent,
    ReportsComponent,
    LoaderComponent,
    SettingsDeviceGroupsComponent,
    SettingsBundlesComponent,
    SettingsUsersComponent,
    SettingsArsComponent,
    BalanceComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatIconModule,
    RouterModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatDialogModule,
    MatButtonModule,
    MatGridListModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTreeModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatTooltipModule
  ],
  providers: [
    TfaChainService,
    MessageService,
    AlertService
  ],
})
export class MyModule { }
