<div class="overlay" id="overlay" [style.display]="alertService.displayProp">
    <div class="container confirm_container">
        <div class="row d-flex justify-content-start" style="margin-left: 1rem;margin-bottom: 0.5rem;font-size: 14px;margin-right: 1rem;">
            {{alertService.message}}
        </div>
        <div class="row d-flex justify-content-end">
            <div class="col-md-6 d-flex">
                <label *ngIf="alertService.showOption" class="d-flex" style="margin-left: 17px;font-size: 14px;font-weight: 400;align-items: center;cursor: pointer;">
                    <input type="checkbox" [(ngModel)]="alertService.checkboxBool"> 
                    <span style="margin-left: 5px;">Don't show again for an hour</span>
                </label>
            </div>
            <div class="col-md-6 d-flex justify-content-end">
                <div>
                    <button aria-label="button" class="btn btn-outline-primary btn-sm" style="font-size: 11px; width: 40px;" (click)="alertService.confirmAction(true)">Ok</button>
                </div>
                <div>
                    <button aria-label="button" class="btn btn-outline-danger btn-sm" style="font-size: 11px;width: 60px;height: 30px;margin-left: 5px;"
                    (click)="alertService.confirmAction(false)">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>