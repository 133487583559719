import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../_enums/url.enums';

@Injectable({
  providedIn: 'root'
})


@Injectable()
export class AuthenticationService {

  constructor(
    private http: HttpClient,
  ) { }

  login(credentials: any) {
    const body = {
      username: credentials.username,
      password: credentials.password
    };
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    const url = `${Url.WebPortalActualURL}auth/login/`;
    return this.http.post<any>(url, body, options);
    
  }

  userInfo(token:string) {
    const url = `${Url.WebPortalActualURL}system/user/`;
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.get<any>(url, { headers });
  }

  logout(): void {
    localStorage.removeItem('jwt_token');
  }

}
