import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Url } from '../../../_enums/url.enums';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { countryCode } from './devices.helper';
import { MessageService } from '../messages/message.service';
import { AlertService } from '../alerts/alert.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {
  isAdmin = false
  isLoading = true
  deviceGroups = []
  showTopUpModal = false
  topUpModel: { from: { year, month, day }, to: { year, month, day }, bundle, imei };
  public years: string[] = ['2019', '2020', '2021','2022','2023'];
  public months: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  public days = {};
  totalAmountDue: String = '';
  bundles: any[];

  currentPage = 0
  currentPageDataUsage = 0
  currentPageDataUsageByCountry = 0
  currentPageDataUsageByDay = 0

  data = []

  displayedColumns: string[] = ['imei', 'name', 'companyName', 'imeiGroup', 'actions'];
  displayedColumnsDataUsage: string[] = ['dataAmount', 'startDate', 'endDate', 'visitedCountry']
  displayedColumnsDataUsageByCountry: string[] = ['dataAmount', 'visitedCountry']
  displayedColumnsDataUsageByDay: string[] = ['dataAmount', 'date']
  displayedColumnPurchaseHostory: string[] = ['plan','price','validity','pd','sd','ed']

  accumulateData = []
  accumulateDataUsage = []
  accumulateDataUsageByCountry = []
  accumulateDataUsageByDay = []

  dataSource: any = new MatTableDataSource();
  dataUsageSource: any = new MatTableDataSource();
  dataUsageByCountrySource: any = new MatTableDataSource()
  dataUsageByDaySource: any = new MatTableDataSource()
  purchaseHistoryData: any = new MatTableDataSource()

  queryInput = ""
  deviceForm: FormGroup;
  deviceByRangeForm: FormGroup
  modalHeaderText = 'Create Device'
  modalButtonText = 'Add'
  additionalData: any = {}
  deviceSettingHeader = ""

  purchaseHistoryLoader = true
  dataUsageLoader = true
  dataUsageByCountryLoader = true
  dataUsageByDayLoader = true

  private alertSubscription: Subscription;

  @ViewChild('paginator1') paginator: MatPaginator;
  @ViewChild('paginator2') dataUsagePaginator: MatPaginator;
  @ViewChild('paginator3') dataUsageByCountryPaginator: MatPaginator;
  @ViewChild('paginator4') dataUsageByDayPaginator: MatPaginator;
  constructor(private http: HttpClient, private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef, private messageService:MessageService,
    private alert: AlertService) {
    this.deviceForm = this.fb.group({
      name: [''],
      imei: ['', [Validators.required, Validators.pattern(/^[0-9]{15}$/)]],
      companyName: [''],
      deviceGroupId: [0, Validators.required],
    });

    this.deviceByRangeForm = this.fb.group({
      imeiFrom: ['', [Validators.required, Validators.pattern(/^[0-9]{15}$/)]],
      imeiTo: ['', [Validators.required, Validators.pattern(/^[0-9]{15}$/)]]
    })
  }

  ngOnInit(): void {
    const days31 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    const days30 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
    const days29 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
    const days28 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];

    for (let yi = 0; yi < this.years.length; yi++) {
      for (let mi = 0; mi < 12; mi++) {
        switch (this.months[mi]) {
          case '1':
          case '3':
          case '5':
          case '7':
          case '8':
          case '10':
          case '12': this.days[this.years[yi] + this.months[mi]] = days31; break;
          case '2': this.days[this.years[yi] + this.months[mi]] = ((this.years[yi] === '2020') ? days29 : days28); break;
          case '4':
          case '6':
          case '9':
          case '11': this.days[this.years[yi] + this.months[mi]] = days30; break;
        }
      }
    }
    this.isAdmin = JSON.parse(localStorage.getItem('user_info')).isAdmin
    this.getReports()
    this.getDeviceGroup()
    this.initializeTopUpModel(null);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataUsageSource.paginator = this.dataUsagePaginator
    this.dataUsageByCountrySource.paginator = this.dataUsageByCountryPaginator
    this.dataUsageByDaySource.paginator = this.dataUsageByDayPaginator
  }

  onPageChange(event: any) {
    if (event.pageIndex > this.currentPage) {
      this.currentPage = event.pageIndex;
      this.getReports();
    }
  }

  initializeTopUpModel(device) {
    const today = new Date();
    this.topUpModel = {
      from: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate()
      },
      to: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: null
      },
      bundle: null,
      imei: device && device.imei
    };
    this.changeDetectorRef.detectChanges();
  }

  periodChanged(topUpModel: any) {
    const fromDate: Date = new Date(topUpModel.from.month + '/' + topUpModel.from.day + '/' + topUpModel.from.year);
    const toDate: Date = new Date(topUpModel.to.month + '/' + topUpModel.to.day + '/' + topUpModel.to.year);

    const days = (Number(toDate) - Number(fromDate)) / (24 * 60 * 60 * 1000);

    if (days > 0) {
      this.totalAmountDue = 8 * days + ' EUR';
    } else {
      this.totalAmountDue = 'Invalid Period!';
    }
  }

  getReports(query = "") {
    const pageSize = 20;
    const url = `${Url.WebPortalActualURL}all/devices/?page=${this.currentPage}&size=20&query=${query}`;
    this.http.get<any>(url).subscribe(
      (res) => {
        if (res?.data && res.data.length > 0) {
          this.accumulateData.push(...res.data);
          this.dataSource = new MatTableDataSource(this.accumulateData.flat());
        } else {
          this.dataSource = new MatTableDataSource([]);
        }

        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false
        console.log(error);
      }
    );
  }

  captureFilterData(event) {
    this.queryInput = event.target.value
  }
  filterData() {
    if (this.queryInput == "") {
      this.getReports()
      return
    }
    const url = `${Url.WebPortalActualURL}all/devices/?page=&size=&query=${this.queryInput}`;
    this.http.get<any>(url).subscribe(
      (res) => {
        if (res?.data && res.data.length > 0) {
          let data = [{ ...res?.data[0], position: 1 }]
          this.dataSource = new MatTableDataSource(data);
        } else {
          this.dataSource = new MatTableDataSource([]);
        }

        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false
      }
    );
  }

  // Get Device Groups
  getDeviceGroup() {
    const url = `${Url.WebPortalActualURL}all/deviceGroup/`;
    this.http.get<any>(url).subscribe(res => {
      const filterData = []
      for (let device of res?.data) {
        if (device.name !== ' ') {
          filterData.push(device)
        }
      }
      this.deviceGroups = filterData
    }, (error) => {
      console.log(error)
    });
  }

  clearForm() {
    this.deviceForm.setValue({
      name: '',
      imei: '',
      companyName: '',
      deviceGroupId: 0
    })
    this.additionalData = {}
    this.deviceByRangeForm.setValue({
      imeiFrom: '',
      imeiTo: ''
    })
  }

  postDevice() {
    const url = `${Url.WebPortalActualURL}update/deviceBasicInfo`
    let payload = {}
    let data = this.deviceForm.value
    if (this.modalButtonText == 'Add') {
      payload = {
        "data":
        {
          "imei": data.imei,
          "name": data.name,
          "companyName": data.companyName,
          "deviceGroupId": parseInt(data.deviceGroupId),
          "rentalDevice": false,
          "corporateDevice": false
        },
        "imeiGroupId": parseInt(data.deviceGroupId)
      }
    } else {
      payload = {
        "data":
        {
          ...this.additionalData,
          "imei": data.imei,
          "name": data.name,
          "companyName": data.companyName,
          "deviceGroupId": parseInt(data.deviceGroupId),
        },
        "imeiGroupId": parseInt(data.deviceGroupId)
      }
      delete payload["data"].imeiGroup;
      delete payload["data"].position;
    }
    this.http.post<any>(url, payload).subscribe(res => {
      this.modalButtonText == 'Add' ? this.messageService.alertSuccess('Created Device') : 
      this.messageService.alertSuccess('Updated Device')
      this.getReports()
    }, (error) => {
      this.messageService.alertError('Error')
    })
  }

  checkActionType(action, data = null) {
    if (action == 'create') {
      this.modalHeaderText = 'Create Device'
      this.modalButtonText = 'Add'
    } else {
      this.modalHeaderText = 'Update Device'
      this.modalButtonText = 'Update'
      this.additionalData = data
      this.deviceForm.setValue({
        name: data.name,
        imei: data.imei,
        companyName: data.companyName,
        deviceGroupId: data.deviceGroupId == -1 ? "0" : data.deviceGroupId
      })
    }
  }

  userDataPopulate(data) {
    this.additionalData = data
    let userName = data?.name ? ` - ${data?.name}` : ""
    let companyName = data?.companyName ? ` - ${data.companyName}` : ""
    let group = data?.imeiGroup?.name ? ` [${data?.imeiGroup?.name}]` : ""
    this.deviceSettingHeader = `${data?.imei}${userName}${companyName}${group}`
    this.getPurchaseHistory()
    this.getDataUsage()
    this.getDataUsageByCountry()
    this.getDataUsageByDay()
    this.getBundles()
  }

  postDeviceRange() {
    const url = `${Url.WebPortalActualURL}create/devices`
    let arr = []
    let from = parseInt(this.deviceByRangeForm.value.imeiFrom)
    let to = parseInt(this.deviceByRangeForm.value.imeiTo)
    for (let i = from; i <= to; i++) {
      arr.push(i)
    }
    let payload = {
      "data": arr
    }
    this.http.post<any>(url, payload).subscribe(res => {
      this.messageService.alertSuccess('Created Devices')
      this.getReports()
    }, (error) => {
      this.messageService.alertError('Error')
    })
  }

  onFileSelected(event: any) {
    const url = `${Url.WebPortalActualURL}create/devices`
    const file = event.target.files[0];
    let arr = []; let rows = []
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileContent = e.target.result;
        rows = fileContent.split(/\r?\n/);
        if (rows[0] == 'IMEI') {
          for (let i = 1; i <= rows.length - 1; i++) {
            arr.push(parseInt(rows[i]))
          }
        }
        let payload = {
          "data": arr
        }
        this.http.post<any>(url, payload).subscribe(res => {
          this.messageService.alertSuccess('Created Devices')
          this.getReports()
        }, (error) => {
          this.messageService.alertError('Error')
        })
      };
      reader.readAsText(file);
    }
  }

  getPurchaseHistory() {
    this.purchaseHistoryLoader = true
    const url = `${Url.WebPortalActualURL}all/purchaseHistory`
    let payload = {
      "imei": this.additionalData.imei
    }
    this.http.post<any>(url, payload).subscribe(res => {
      const purchaseHistory = res?.data.map(historyRecord => {
        const validity: number = historyRecord.bundleValidity / (1000 * 60 * 60 * 24);
        let bundlePriceFinal = (Number.parseInt(historyRecord.bundlePrice.toString()) / 100);
        if (historyRecord.bundleCode === 'Roamability_500M_DP') {
          bundlePriceFinal = bundlePriceFinal * validity;
        }
        return {
          bundleCode: historyRecord.bundleCode,
          bundleName: historyRecord.bundleName,
          bundlePrice: isNaN(bundlePriceFinal) ? 0 : bundlePriceFinal.toFixed(2),
          bundleValidity: Math.round(validity * 100) / 100,
          purchaseDate: new Date(Number.parseInt(historyRecord.purchaseDate)).toLocaleDateString(),
          startDate: new Date(Number.parseInt(historyRecord.startDate)).toLocaleDateString(),
          expiryDate: new Date(Number.parseInt(historyRecord.expiryDate)).toLocaleDateString()
        };
      });
      this.purchaseHistoryData = new MatTableDataSource(purchaseHistory)
      this.purchaseHistoryLoader = false
    }, (error) => {
      this.purchaseHistoryLoader = false
      console.log(error)
    })
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  }

  // Very Important to clean data on modal close
  clearDeviceSettingsModal() {
    this.purchaseHistoryData = []
    this.dataUsageSource = new MatTableDataSource([]);
    this.dataUsageByCountrySource = new MatTableDataSource([]);
    this.dataUsageByDaySource = new MatTableDataSource([]);

    this.accumulateDataUsage = []
    this.accumulateDataUsageByCountry = []
    this.accumulateDataUsageByDay = []

    this.dataUsageLoader = true
    this.dataUsageByCountryLoader = true
    this.dataUsageByDayLoader = true

    this.currentPageDataUsage = 0
    this.currentPageDataUsageByCountry = 0
    this.currentPageDataUsageByDay = 0

    this.initializeTopUpModel(null)
    this.showTopUpModal = false
  }

  getDataUsage() {
    const pageSize = 20
    const url = `${Url.WebPortalActualURL}all/dataUsage`;
    let payload = {
      "imei": this.additionalData.imei,
      "page": this.currentPageDataUsage,
      "pageSize": pageSize,
      "orderProperty": "start_date",
      "orderDirection": "desc"
    }
    this.http.post<any>(url, payload).subscribe(
      (res) => {
        if (res?.data && res.data.length > 0) {
          // const startPosition = this.currentPageDataUsage * pageSize + 1;
          res.data.forEach((item, index) => {
            // item.position = startPosition + index;
            item.dataAmount = this.formatBytes(item.dataAmount)
            item.visitedCountry = countryCode[item.visitedCountry]
            item.endDate = new Date(item.endDate).toLocaleString()
            item.startDate = new Date(item.startDate).toLocaleString()
          });
          this.accumulateDataUsage.push(...res.data);

          this.dataUsageSource = new MatTableDataSource(this.accumulateDataUsage.flat());
        } else {
          if (this.dataUsageSource.data.length == 0) {
            this.dataUsageSource = new MatTableDataSource([]);
          }
        }

        this.dataUsageSource.paginator = this.dataUsagePaginator;
        this.dataUsageLoader = false;
      },
      (error) => {
        this.dataUsageLoader = false
        console.log(error);
      }
    );
  }

  getDataUsageByCountry() {
    const pageSize = 20
    const url = `${Url.WebPortalActualURL}all/dataUsageGroupedByCountry`;
    let payload = {
      "imei": this.additionalData.imei,
      "page": this.currentPageDataUsageByCountry,
      "pageSize": pageSize,
      "orderProperty": "startDate",
      "orderDirection": "desc"
    }
    this.http.post<any>(url, payload).subscribe(
      (res) => {
        if (res?.data && res.data.length > 0) {
          // const startPosition = this.currentPageDataUsage * pageSize + 1;
          res.data.forEach((item, index) => {
            // item.position = startPosition + index;
            item.dataAmount = this.formatBytes(item.dataAmount)
            item.visitedCountry = countryCode[item.visitedCountry]
          });
          this.accumulateDataUsageByCountry.push(...res.data);

          this.dataUsageByCountrySource = new MatTableDataSource(this.accumulateDataUsageByCountry.flat());
        } else {
          if (this.dataUsageByCountrySource.data.length == 0) {
            this.dataUsageByCountrySource = new MatTableDataSource([]);
          }
        }

        this.dataUsageByCountrySource.paginator = this.dataUsageByCountryPaginator;
        this.dataUsageByCountryLoader = false;
      },
      (error) => {
        this.dataUsageByCountryLoader = false
        console.log(error);
      }
    );
  }

  getDataUsageByDay() {
    const pageSize = 20
    const url = `${Url.WebPortalActualURL}all/dataUsageGroupedByDay`;
    let payload = {
      "imei": this.additionalData.imei,
      "page": this.currentPageDataUsageByCountry,
      "pageSize": pageSize,
      "orderProperty": "startDate",
      "orderDirection": "desc"
    }
    this.http.post<any>(url, payload).subscribe(
      (res) => {
        if (res?.data && res.data.length > 0) {
          // const startPosition = this.currentPageDataUsage * pageSize + 1;
          res.data.forEach((item, index) => {
            // item.position = startPosition + index;
            item.dataAmount = this.formatBytes(item.dataAmount)
            item.startDate = new Date(item.startDate).toLocaleString()
          });
          this.accumulateDataUsageByDay.push(...res.data);

          this.dataUsageByDaySource = new MatTableDataSource(this.accumulateDataUsageByDay.flat());
        } else {
          if (this.dataUsageByDaySource.data.length == 0) {
            this.dataUsageByDaySource = new MatTableDataSource([]);
          }
        }

        this.dataUsageByDaySource.paginator = this.dataUsageByCountryPaginator;
        this.dataUsageByDayLoader = false;
      },
      (error) => {
        this.dataUsageByDayLoader = false
        console.log(error);
      }
    );
  }

  onPageChangeDataUsage(event: any) {
    if (event.pageIndex > this.currentPageDataUsage) {
      this.currentPageDataUsage = event.pageIndex;
      this.getDataUsage();
    }
  }

  onPageChangeDataUsageByCountry(event: any) {
    if (event.pageIndex > this.currentPageDataUsageByCountry) {
      this.currentPageDataUsageByCountry = event.pageIndex;
      this.getDataUsageByCountry();
    }
  }

  onPageChangeDataUsageByDay(event: any) {
    if (event.pageIndex > this.currentPageDataUsageByDay) {
      this.currentPageDataUsageByDay = event.pageIndex;
      this.getDataUsageByDay();
    }
  }

  markDevice(bool: boolean) {
    bool ? this.alert.openAlert('Are you sure, you want to mark the device as corporate ?',false) :
    this.alert.openAlert('Are you sure, you want to move the device to regular stock ?',false) 
    this.alertSubscription = this.alert.getConfirmResult().pipe(take(1)).subscribe((result) => {
      if (result[0] == false) {
        return
      } else {
        const url = `${Url.WebPortalActualURL}update/device`;
        let data = this.additionalData
        let payload = {
          "data": {
            "id": data.id,
            "imei": data.imei,
            "name": data.name,
            "companyName": data.companyName,
            "deviceGroupId": data.deviceGroupId,
            "distributorId": data?.distributor?.id ? data?.distributor?.id : null,
            "resellerId": data?.reseller?.id ? data?.reseller?.id : null,
            "accountId": data?.account?.id ? data?.account?.id : null,
            "endUserId": data?.endUser?.id ? data?.endUser?.id : null,
            "rentalDevice": data?.isRental ? data?.isRental : false,
            "corporateDevice": bool
          }
        }
        this.http.post<any>(url, payload).subscribe(res => {
          bool ? this.messageService.alertSuccess('Device marked as Corporate') : 
          this.messageService.alertSuccess('Device moved to regular stock')
          this.additionalData = res?.newEntity
          this.dataSource.data.forEach((item, index) => {
            if (item?.imei == res?.newEntity?.imei) {
              item.isCorporate = bool
            }
          })
        }, (error) => {
          this.messageService.alertError('Error')
        })
      }
    })
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  topUpDevice(topUpModel: { from: { year; month; day }; to: { year; month; day }; bundle; imei }) {
    const url = `${Url.WebPortalActualURL}payment/topup`
    topUpModel.imei = this.additionalData.imei
    let payload = {
      bundle : this.topUpModel.bundle,
      from : (topUpModel.from.year && topUpModel.from.month && topUpModel.from.day) ? topUpModel.from.year + '/' + topUpModel.from.month + '/' + topUpModel.from.day : null,
      imei :  this.additionalData.imei,
      to : (this.topUpModel.bundle == "Roamability_500M_DP") ? topUpModel.to.year + '/' + topUpModel.to.month + '/' + topUpModel.to.day : null,
    }
    this.http.post<any>(url,payload).subscribe(res => {
      this.messageService.alertSuccess('Updated Top Up Balance')
      this.showTopUpModal = false
    },(error) => {
      this.messageService.alertError('Error')
      this.showTopUpModal = false
    })
  }

  getBundles() {
    this.http.get<{ bundles: any[] }>(`https://portal.we.stream/MyStageAccount/user/bundles`, {
      params: {
        imei: this.additionalData.imei,
        country: 'empty'
      }
    }).subscribe(res => {
      this.bundles = res?.bundles.map ( (bundle) => {
        return {
          code: bundle.goodsCode,
          name: bundle.goodsName.replace(new RegExp('<br/>', 'g'), ' '),
          price: bundle.goodsPrice / 100
        };
      });
    },(error) => {
      console.log(error)
    })
  }

  ngOnDestroy() {
    if(this.alertSubscription !== undefined){
      this.alertSubscription.unsubscribe();
    }
  }

}
