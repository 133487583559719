export class TfaFilter {
  id: number;
  propertyName: String;
  comparator: String;
  value: String;
  comparators: Array<any>;
  dataType: String;
  baseFilterId: number;

  constructor(id: number, propertyName: String, comparator: String, value: String, comparators: Array<any>, dataType: String, baseFilterId: number) {
    this.id = id;
    this.propertyName = propertyName;
    this.comparator = comparator;
    this.value = value;
    this.dataType = dataType;
    this.comparators = comparators;
    this.baseFilterId = baseFilterId;
  }
}
