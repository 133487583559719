import { Component, OnInit } from '@angular/core';
import { Reseller } from '../network/_classes/reseller';
import { TfaChain } from './tfa-chain';
import { TfaChainService } from './tfa-chain.service';
import { TfaFilter } from './tfa-filter';
import { TfaAction } from './tfa-action';
import { TfaActionParameter } from './tfa-action-parameter';
import { MessageService } from '../messages/message.service';
import { AlertService } from '../alerts/alert.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-settings-ars',
  templateUrl: './settings-ars.component.html',
  styleUrls: ['./settings-ars.component.css']
})
export class SettingsArsComponent implements OnInit {

  public tfaChains: TfaChain[];
  public tfaChain: TfaChain;
  public resellers: Reseller[];
  public openUserForm: boolean;
  public formIsClosed: boolean;
  public openAddForm: boolean;
  public triggersList: [{ id, name }];
  public propertiesList: [{ id, name }];
  public comparatorsList: [{ id, name }];
  public actionsList: [{ id, name }];
  public actionParametersList: [{ id, name }];
  public daysPropList: [{ id, name }];
  public countryPropsList: [{ id, name }];
  public generatedFlowPropsList: [{ id, name }];
  public selectedAction: any;
  public selectedTrigger: any;
  public comparatorCaptionMap: {};
  isLoading: boolean = true

  private alertSubscriptionDelete: Subscription;
  private deleteChainAlertBoolean: boolean = false

  private intervalId:any

  constructor(private tfaChainService: TfaChainService, private messageService: MessageService,
    private alert: AlertService) {
    this.openUserForm = false;
    this.formIsClosed = true;
  }

  ngOnInit() {
    const defaultParams = [new TfaActionParameter('', ''), new TfaActionParameter('', '')];
    const self = this;
    self.tfaChain = new TfaChain(-1, '', { id: -1, name: '' }, [new TfaFilter(-1, '', '',
      '', [], '', -1)], new TfaAction(-1, '', defaultParams));
    self.openAddForm = false;
    self.comparatorCaptionMap = {
      LT: '<',
      LTEQ: '<=',
      GT: '>',
      GTEQ: '>=',
      EQ: '=',
      IN: 'in'
    };

    this.loadTfaChains();

    this.tfaChainService.getTfaTriggers().subscribe(
      response => {
        console.log(response.data)
        self.triggersList = response.data;
      }
    );

    this.tfaChainService.getTfaActions().subscribe(
      response => {
        console.log(response.data)
        self.actionsList = response.data;
      }
    );

    this.computeDeleteChainTime()
    
  }

  loadTfaChains() {
    const self = this;

    this.tfaChainService.getTfaChain().subscribe(
      response => {
        self.tfaChains = response.data.map(chainDTO => {
          const trigger = { id: chainDTO.trigger.id, name: chainDTO.trigger.name };
          const filters: TfaFilter[] = chainDTO.filterParameters.map(filterParameterValue => {
            return new TfaFilter(
              filterParameterValue.id,
              filterParameterValue.filter.propertyName,
              self.comparatorCaptionMap[filterParameterValue.comparator.type],
              filterParameterValue.value,
              filterParameterValue.filter.comparators,
              filterParameterValue.filter.dataType,
              filterParameterValue.filter.id
            );
          });

          const actionParameters: TfaActionParameter[] = chainDTO.actionParameters.map(actionParameterValue => {
            return new TfaActionParameter(
              actionParameterValue.actionParameter.name,
              actionParameterValue.value
            );
          });
          const action: TfaAction = new TfaAction(chainDTO.action.id, chainDTO.action.name, actionParameters);
          return new TfaChain(chainDTO.id, chainDTO.name, trigger, filters, action);
        });
        console.log(self.tfaChains)
        this.isLoading = false
      },
      error => {
        this.isLoading = false
        console.log(error);
      });
  }

  addTfaChain() {
    const self = this;
    const actionParameterValues: Array<{ actionParameterId, value }> = new Array<{ actionParameterId, value }>();
    const filterParameterValues: Array<{ filterId, comparatorId, value }> = this.tfaChain.filterValues.map(filterValue => {
      return {
        filterId: filterValue.id,
        comparatorId: filterValue.comparatorId,
        value: filterValue.value
      };
    });

    for (const apIndex in this.tfaChain.action.parameterValues) {
      if (this.tfaChain.action.parameterValues.hasOwnProperty(apIndex)) {
        const actionParameterValue: any = {
          actionParameterId: apIndex,
          value: this.tfaChain.action.parameterValues[apIndex]
        };
        actionParameterValues.push(actionParameterValue);
      }
    }

    const tfaChainDTO: {
      id, name,
      triggerId, actionId,
      filterParametersValues: Array<{ filterId, comparatorId, value }>,
      actionParameterValues: Array<{ actionParameterId, value }>
    } = {
      id: null,
      name: this.tfaChain.name,
      triggerId: this.tfaChain.trigger.id,
      actionId: this.tfaChain.action.id,
      filterParametersValues: filterParameterValues,
      actionParameterValues: actionParameterValues
    };

    if (this.tfaChain.id !== '-1') {
      tfaChainDTO.id = this.tfaChain.id;
      this.tfaChainService.editTfaChain(tfaChainDTO)
        .subscribe(response => {
          self.openAddForm = false;
          self.loadTfaChains();
        }, error => {
          console.log(error);
        });
    } else {
      this.tfaChainService.createTfaChain(tfaChainDTO)
        .subscribe(response => {
          self.openAddForm = false;
          self.loadTfaChains();
        }, error => {
          console.log(error);
        });
    }
  }

  editChain(chain: TfaChain) {
    this.openAddForm = true;
    this.tfaChain = chain;
    this.triggerChanged();
    this.tfaChain.filterValues = chain.filters.map(filterObject => {
      return {
        value: filterObject.value,
        id: filterObject.baseFilterId + '',
        comparatorId: filterObject.comparators.filter(comp => this.comparatorCaptionMap[comp.type] === filterObject.comparator)[0].id,
        comparators: filterObject.comparators,
        dataType: filterObject.dataType
      };
    });
    this.actionChanged();
    this.selectedAction.parameterValues = {};
    for (let i = 0; i < this.selectedAction.parameters.length; i++) {
      const parameter = this.selectedAction.parameters[i];
      parameter.value = chain.action.parameters.filter(param => {
        return param.name === parameter.name;
      })[0].value;
      this.tfaChain.action.parameterValues[parameter.id] = parameter.value;
    }
  }

  closeForm() {
    const defaultParams = [new TfaActionParameter('', ''), new TfaActionParameter('', '')];
    this.tfaChain = new TfaChain(-1, '', { id: -1, name: '' },
      [new TfaFilter(-1, '', '', '', [], '', -1)],
      new TfaAction(-1, '', defaultParams));
    this.selectedAction = null
  }

  deleteChain(chain: TfaChain) {
    if (this.deleteChainAlertBoolean == false) {
      this.alert.openAlert('Are you sure, you want to delete this TFA Chain ?',true)
      this.alertSubscriptionDelete = this.alert.getConfirmResult().pipe(take(1)).subscribe((result) => {
        if (result[0] == false) {
          return
        } else {
          this.deleteChainAlertBoolean = result[1]
          if(result[1]){
            let startDate = new Date().getTime()
            sessionStorage.setItem('deleteChainAlertBoolean',`${result[1]}-${startDate}`)
            this.intervalId = setInterval(() => {
              this.computeDeleteChainTime();
            },60000);
          }
          const chainToBeDeleted = this.tfaChains.indexOf(chain);
          const self = this;
          if (chainToBeDeleted !== -1) {
            this.tfaChainService.deleteTfaChain(chain)
              .subscribe(response => {
                self.tfaChains.splice(chainToBeDeleted, 1);
              }, error => {
              });
          }
        }
      })
    } else {
      const chainToBeDeleted = this.tfaChains.indexOf(chain);
      const self = this;
      if (chainToBeDeleted !== -1) {
        this.tfaChainService.deleteTfaChain(chain)
          .subscribe(response => {
            self.tfaChains.splice(chainToBeDeleted, 1);
          }, error => {
          });
      }
    }
  }

  computeDeleteChainTime(){
    let res = sessionStorage.getItem('deleteChainAlertBoolean')
    if(res == undefined || res == null){
      return
    }
    let arr = res.split('-')
    let startTime = parseInt(arr[1])
    // 3600000,30000 - 600000,1000
    if((new Date().getTime() - startTime) >= 3600000){
      this.deleteChainAlertBoolean = false
      clearInterval(this.intervalId)
      sessionStorage.clear()
    }else{
      this.deleteChainAlertBoolean = Boolean(arr[0])
    }
  }

  triggerChanged() {
    const trigger = this.triggersList.filter(actionItem => (actionItem.id + '') === this.tfaChain.trigger.id + '');
    if (trigger.length > 0) {
      this.selectedTrigger = trigger[0];
      this.tfaChain.resetFilterValues(this.selectedTrigger.filters);
    }
  }

  actionChanged() {
    const action = this.actionsList.filter(actionItem => (actionItem.id + '') === this.tfaChain.action.id + '');
    if (action.length > 0) {
      this.selectedAction = action[0];
    }
  }

  addFilterValue() {
    this.tfaChain.filterValues.push(this.tfaChain.getDefaultFilterValues());
    this.filterChanged(this.tfaChain.filterValues[this.tfaChain.filterValues.length - 1], this.selectedTrigger);
  }

  filterChanged(filterValue: { id; comparatorId; value; comparators; dataType }, selectedTrigger: any) {
    const filters = selectedTrigger.filters.filter(filterItem => filterItem.id + '' === filterValue.id + '');
    if (filters.length > 0) {
      filterValue.comparators = filters[0].comparators;
      filterValue.dataType = filters[0].dataType;
    }
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  deleteFilterValue(filterValue: { id: number; comparatorId; value; comparators; dataType }) {
    const indexToBeDeleted = this.tfaChain.filterValues.indexOf(filterValue);
    if (indexToBeDeleted > -1) {
      this.tfaChain.filterValues.splice(indexToBeDeleted, 1);
    }
  }

  showAddTfaChain() {
    const defaultParams = [new TfaActionParameter('', ''), new TfaActionParameter('', '')];
    this.tfaChain = new TfaChain(-1, '', { id: -1, name: '' }, [new TfaFilter(-1, '', '',
      '', [], '', -1)], new TfaAction(-1, '', defaultParams));
    this.openAddForm = true;
    this.selectedTrigger = undefined;
  }

  ngOnDestroy() {
    if (this.alertSubscriptionDelete !== undefined) {
      this.alertSubscriptionDelete.unsubscribe();
    }
  }
}
