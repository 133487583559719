<div class="container" style="height: 80vh;">
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
        <div class="row" style="width: 800px;">
            <div class="col-md-6 mx-auto center-card">
                <div class="card" style="background-color:#F7F8F8;">
                    <div class="card-header" style="background-color:#F7F8F8;">
                        <h5 style="font-family:Montserrat, sans-serif;"><b>Current Status</b></h5>
                    </div>
                    <div class="card-body d-flex justify-content-center" style="margin-top: 20px;">
                        <p style="font-family:Montserrat, sans-serif;"><b>Your Balance is : {{balance}} EUR</b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>