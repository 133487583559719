<div class="container mt-4" style="max-width: 1000px;">
    <mat-button-toggle-group style="margin-bottom: 12px;" class="small-button-toggle-group">
        <mat-button-toggle [value]="0" [checked]="true" (click)="toggleView(0)" matTooltip="Grid View">
            <mat-icon style="font-size: 25px;color: #454545;">grid_on</mat-icon> <!-- Replace 'format_bold' with your desired icon name -->
        </mat-button-toggle>
        <mat-button-toggle [value]="1" (click)="toggleView(1)"  matTooltip="List View">
            <mat-icon style="font-size: 25px;color: #454545;">list_alt</mat-icon> <!-- Replace 'format_italic' with your desired icon name -->
        </mat-button-toggle>
    </mat-button-toggle-group>
    <!-- Search bar and Add Device button -->
    <div class="row mb-3">
        <div class="col-md-10">
            <div class="input-group">
                <input style="font-size: 14px;border: 1px solid #727272;" class="form-control" type="text"
                    placeholder="Search Users" (keyup)="onSearch($event)">
                <span class="input-group-text" style="border: 1px solid #727272;background: #ffff;">
                    <mat-icon class="center-align" style="font-size: 23px;color:#727272;">search</mat-icon>
                </span>
            </div>
        </div>
        <div class="col-md-2">
            <button aria-label="button" type="button" class="center-align cs-bg-button" data-bs-toggle="modal"
                data-bs-target="#addDeviceModel" (click)="checkActionType('create')">
                <mat-icon style="font-size: 17px;display: flex;align-items: center;">person_add</mat-icon>
                <span class="center-align" style="font-size: 14px;">Add User</span>
            </button>
        </div>
    </div>

    <!-- List of device cards -->
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row" *ngIf="toggleVal == 0">
        <div class="col-md-4" *ngFor="let user of filterUsersList">
            <mat-card class="mt-2 custom-card">
                <mat-card-content class="d-flex row">
                    <div class="col-md-9 d-flex justify-content-start align-items-center">
                        <mat-icon style="display: flex;font-size: 20px;margin-right: 5px;color: #454545;">person</mat-icon>
                        <h6 class="card-text" style="font-weight: bolder;font-size: 14px;">{{ user.firstName + ' ' + user.lastName}}</h6>
                    </div>
                    <div class="col-md-3 d-flex justify-content-end">
                        <div  class="center-align" *ngIf="user.roles[0] == 'RESELLER'">
                            <h6 style="font-size: 12px; font-weight: bold;" class="card-text text-secondary mb-0">{{ user.roles[0] }}</h6>
                        </div>
                        <div class="center-align" *ngIf="user.roles[0] == 'ADMIN'">
                            <h6 style="font-size: 12px; font-weight: bold;" class="card-text text-secondary mb-0">{{ user.roles[0] }}</h6>
                        </div>
                        <div class="center-align" *ngIf="user.roles[0] == 'DISTRIBUTOR'">
                            <h6  style="font-size: 12px; font-weight: bold;" class="card-text text-secondary mb-0">{{ user.roles[0] }}</h6>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-content *ngIf="user.roles[0] == 'RESELLER'">
                    <p class="text-secondary mb-0 d-flex">
                        <mat-icon class="cs-color-primary" style="font-size: 20px;color: #454545;">business</mat-icon>
                        <span>{{user.reseller.name}}</span>
                    </p>
                    <p class="text-secondary mt-0 d-flex">
                        <mat-icon class="cs-color-primary" style="font-size: 20px;color: #454545;">public</mat-icon>
                        <span>{{user.reseller.country}}</span>
                    </p>
                </mat-card-content>
                <mat-card-content *ngIf="user.roles[0] == 'ADMIN'">
                    <p class="text-secondary mb-0 d-flex">
                        <mat-icon class="cs-color-primary" style="font-size: 20px;color: #454545;">business</mat-icon>
                        <span>{{'ADMIN'}}</span>
                    </p>
                    <p class="text-secondary mt-0 d-flex">
                        <mat-icon class="cs-color-primary" style="font-size: 20px;color: #454545;">supervised_user_circle</mat-icon>
                        <span>{{'Admin User'}}</span>
                    </p>
                </mat-card-content>
                <mat-card-content *ngIf="user.roles[0] == 'DISTRIBUTOR'">
                    <p class="text-secondary mb-0 d-flex">
                        <mat-icon class="cs-color-primary" style="font-size: 20px;color: #454545;">business</mat-icon>
                        <span>{{user.distributor.name}}</span>
                    </p>
                    <p class="text-secondary mt-0 d-flex">
                        <mat-icon class="cs-color-primary" style="font-size: 20px;color: #454545;">public</mat-icon>
                        <span>{{user.distributor.country}}</span>
                    </p>
                </mat-card-content>
                <mat-card-content>
                    <p class="mb-0">
                        <strong>Username</strong>
                    </p>
                    <p class="text-dark mt-0">
                        {{ user.username }}
                    </p>
                </mat-card-content>
                <mat-card-actions class="d-flex" style="justify-content: flex-start;margin-left: 1px;">
                    <div style="margin-right: 5px;">
                        <button aria-label="button" class="center-align btn btn-outline-primary btn-sm" data-bs-toggle="modal"
                            data-bs-target="#addDeviceModel" (click)="checkActionType('edit',user)">
                            <!-- <mat-icon style="font-size: 15px;display: flex;align-items: center;">edit</mat-icon> -->
                            <span class="center-align" style="font-size: 11px;">Edit</span>
                        </button>
                    </div>
                    <div *ngIf="user.roles[0] == 'DISTRIBUTOR' || user.roles[0] == 'RESELLER'">
                        <button aria-label="button" class="center-align btn btn-outline-dark btn-sm" data-bs-toggle="modal"
                            data-bs-target="#roleInfoModel" (click)="emitDetails(user)">
                            <!-- <mat-icon style="font-size: 17px;display: flex;align-items: center;">info</mat-icon> -->
                            <span class="center-align" style="font-size: 11px;"><b>{{'Details'}}</b></span>
                        </button>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

    <mat-table style="font-family: Montserrat, sans-serif;" [dataSource]="dataSource" *ngIf="toggleVal == 1">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>
                <b>Name</b>
                <mat-icon style="cursor: pointer;" *ngIf="sortDirection == 0" (click)="onSort(1)">arrow_drop_up</mat-icon>
                <mat-icon style="cursor: pointer;" *ngIf="sortDirection == 1" (click)="onSort(0)">arrow_drop_down</mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let user"><b>{{ user.firstName + ' ' + user.lastName }}</b></mat-cell>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef><b>Role</b></mat-header-cell>
            <mat-cell *matCellDef="let user">{{ user.roles[0] }}</mat-cell>
        </ng-container>

        <!-- Details Column -->
        <ng-container matColumnDef="details">
            <mat-header-cell *matHeaderCellDef style="flex: 0.5;"><b>Details</b></mat-header-cell>
            <mat-cell *matCellDef="let user" style="flex: 0.5;">
                <button aria-label="button" mat-icon-button data-bs-toggle="modal" data-bs-target="#roleInfoModel"
                    (click)="emitDetails(user)" *ngIf="user.roles[0] == 'DISTRIBUTOR' || user.roles[0] == 'RESELLER'">
                    <mat-icon style="color: #454545;">info</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!-- Username Column -->
        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef style="flex: 2;"><b>Username</b></mat-header-cell>
            <mat-cell *matCellDef="let user" style="flex: 2;">{{ user.username }}</mat-cell>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef style="flex: 0.5;"><b>Actions</b></mat-header-cell>
            <mat-cell *matCellDef="let user" style="flex: 0.5;">
                <button aria-label="button" mat-icon-button data-bs-toggle="modal" data-bs-target="#addDeviceModel"
                    (click)="checkActionType('edit', user)">
                    <mat-icon style="color: #454545;">edit</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>


    <!-- Modal -->
    <div class="modal fade" id="addDeviceModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style="background-color:#ffff;">
                <form [formGroup]="userForm" novalidate class="settingsForm">
                    <div class="modal-header">
                        <h5 style="margin-left: 10px;" class="modal-title">{{modalHeaderText}}</h5>
                    </div>
                    <div>
                        <div class="radio-buttons">
                            <label>
                                <input type="radio" name="userType" (click)="handleRole(0)" value="Distributor"
                                    [checked]="selectedUserType === 0">
                                Distributor
                            </label>
                            <label>
                                <input type="radio" name="userType" (click)="handleRole(1)" value="Reseller"
                                    [checked]="selectedUserType === 1">
                                Reseller
                            </label>
                            <label>
                                <input type="radio" name="userType" (click)="handleRole(2)" value="Admin"
                                    [checked]="selectedUserType === 2"> Admin
                            </label>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="First Name" formControlName="firstName"
                                required minlength="1">
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="Last Name" formControlName="lastName"
                                required minlength="1">
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="Username" formControlName="username"
                                required minlength="1">
                        </div>
                        <div class="form-group" *ngIf="modalButtonText === 'Add'">
                            <input class="form-control" type="text" placeholder="Password" formControlName="password"
                                required minlength="1">
                        </div>
                        <div class="form-group" *ngIf="selectedUserType === 0">
                            <!-- Dropdown for Distributor specific options -->
                            <select class="form-control" formControlName="distributorId" required>
                                <option [value]="null" disabled selected>Select Distributor</option>
                                <option [value]="dist.id" *ngFor="let dist of distributorList">{{ dist.name }}</option>
                            </select>
                        </div>
                        <div class="form-group" *ngIf="selectedUserType === 1">
                            <!-- Dropdown for Reseller specific options -->
                            <select class="form-control" formControlName="resellerId" required>
                                <option [value]="null" disabled selected>Select Reseller</option>
                                <option [value]="reseller.id" *ngFor="let reseller of resellerList">{{ reseller.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer p-1">
                        <button aria-label="button" type="submit" class="btn btn-outline-success" data-bs-dismiss="modal"
                            (click)="postRequest()">{{modalButtonText}}</button>
                        <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                            (click)="clearForm()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- The Info Modal -->
    <div class="modal fade" id="roleInfoModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h5 class="modal-title d-flex" id="exampleModalLabel">Details for {{roleInfo.name}}
                    </h5>
                </div>
                <!-- Modal Body -->
                <div class="modal-body">
                    <table class="table">
                        <tbody style="border-radius: 10px;">
                            <tr>
                                <th scope="row" class="col-sm-4">Country:</th>
                                <td class="col-sm-8">{{roleInfo.country}}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-sm-4">City:</th>
                                <td class="col-sm-8">{{roleInfo.city}}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-sm-4">Postal Code:</th>
                                <td class="col-sm-8">{{roleInfo.postalCode}}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-sm-4">Bank Country:</th>
                                <td class="col-sm-8">{{roleInfo.bankCountry}}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-sm-4">Balance:</th>
                                <td class="col-sm-8">{{roleInfo.balance ? roleInfo.balance : '0.0 EUR'}}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-sm-4">Address Line 1:</th>
                                <td class="col-sm-8">{{roleInfo.addressLine1 ? roleInfo.addressLine1 : 'N/A'}}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-sm-4">Address Line 2:</th>
                                <td class="col-sm-8">{{roleInfo.addressLine2 ? roleInfo.addressLine2 : 'N/A'}}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-sm-4">VAT Number:</th>
                                <td class="col-sm-8">{{roleInfo.vatNumber}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Modal Footer -->
                <div class="modal-footer">
                    <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>



</div>