import { Injectable } from '@angular/core';


@Injectable()
export class MessageService {
  successMessage: string;
  errorMessage: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  private successTimeout: number;
  private errorTimeout: number;

  private clearSuccessMessage = () => {
    clearTimeout(this.successTimeout);
    window.setTimeout(() => {
      this.successMessage = undefined;
    }, 1100);
  }

  private clearErrorMessage = () => {
    clearTimeout(this.errorTimeout);
    window.setTimeout(() => {
      this.errorMessage = undefined;
    }, 1100);
  }

  alertSuccess(message: string): void {
    this.successMessage = '';
    this.successMessage = message;
    this.showSuccessMessage = true;
    this.successTimeout = window.setTimeout(() => {
      this.showSuccessMessage = false;
      this.clearSuccessMessage();
    }, 5000);
  }

  alertError(message: string): void {
    this.errorMessage = '';
    this.errorMessage = message;
    this.showErrorMessage = true;
    this.errorTimeout = window.setTimeout(() => {
      this.showErrorMessage = false;
      setTimeout(() => {
        this.errorMessage = undefined;
      }, 1000);
    }, 5000);
  }

  closeSuccessMessage(): void {
    this.showSuccessMessage = false;
    this.clearSuccessMessage();
  }

  closeErrorMessage(): void {
    this.showErrorMessage = false;
    this.clearErrorMessage();
  }

}
