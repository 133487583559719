import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Url } from '../../_enums/url.enums';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  username: string;
  isDistributor: boolean;
  isReseller: boolean;
  isAdmin: boolean;
  firstName: string;

  constructor(private router: Router, private http: HttpClient) {
    this.username = 'Guest User';
    this.isAdmin = true;
    this.isDistributor = true;
    this.isReseller = true
  }

  ngOnInit(): void {
    if (localStorage.getItem('jwt_token') == undefined) {
      this.router.navigate([''])
    }else{
      let info = JSON.parse(localStorage.getItem('user_info'))
      this.username = info.firstName + ' ' + info.lastName
      this.isAdmin = info.roles.indexOf('ADMIN') !== -1;
      this.isDistributor = info.roles.indexOf('DISTRIBUTOR') !== -1;
      this.isReseller = info.roles.indexOf('RESELLER') !== -1;
    }
  }

  logout(){
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('user_info')
    this.router.navigate([''])
  }

}
