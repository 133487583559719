import { RoleType } from '../../_enums/role-type.enum';
import { IDeviceHistory } from '../../_interfaces/device-history.interface';
import { IDevice } from '../../_interfaces/device.interface';
import { IRole } from '../../_interfaces/role.interface';
import { IServerRole } from '../../_interfaces/server.role.interface';
import { Device } from './device';

export abstract class Role implements IRole {
  role: RoleType;
  id: number;
  uniqueId: string;
  companyName: string;
  vatNumber: number;
  balance: number;
  country: string;
  city: string;
  postalCode: string;
  address1: string;
  address2: string;
  contactPerson: string;
  email: string;
  bankCountry: string;
  additionalBankInfo: string;
  selected: boolean;
  devices: IDevice[];
  deviceHistory: IDeviceHistory[];
  imeiRanges: {from, to}[];

  constructor(data: IServerRole) {
    if (!data) { return; }
    this.role = data.role;
    this.id = data.id;
    this.companyName = data.name;
    this.uniqueId = this.id + '-' + this.companyName + '-' + new Date().getTime();
    this.vatNumber = data.vatNumber;
    this.country = data.country;
    this.city = data.city;
    this.postalCode = data.postalCode;
    this.address1 = data.addressLine1;
    this.address2 = data.addressLine2;
    this.contactPerson = data.contactPerson;
    this.email = data.email;
    this.bankCountry = data.bankCountry;
    this.additionalBankInfo = data.bankInformation;
    this.balance = data.balance;
    this.devices = (data.devices && data.devices.map(device => {
      return Device.buildDevice(device);
    })) || [];

    this.devices.sort((l, r) => l.imei > r.imei ? 1 : -1);
    this.imeiRanges = [];
    if (this.devices.length) {
      let imeiRange = {from: undefined, to: undefined};
      for (let i = 0; i < this.devices.length - 1; i++) {
        if (imeiRange.from === undefined) {
          imeiRange.from = this.devices[i].imei;
          continue;
        }
        if (this.devices[i + 1].imei !== (this.devices[i].imei + 1)) {
          imeiRange.to = this.devices[i].imei;
          this.imeiRanges.push(imeiRange);
          imeiRange = {from: undefined, to: undefined};
        }
      }
      if (imeiRange.from === undefined) {
        imeiRange.to = imeiRange.from = this.devices[this.devices.length - 1].imei;
      } else if (imeiRange.to === undefined) {
        imeiRange.to = this.devices[this.devices.length - 1].imei;
      }
      this.imeiRanges.push(imeiRange);
    }
    this.selected = false;
  }

  getDTO(): any {
    return {
      id: this.id,
      role: this.role,
      name: this.companyName,
      country: this.country,
      city: this.city,
      postalCode: this.postalCode,
      addressLine: this.address1,
      addressLine2: this.address2,
      contactPerson: this.contactPerson,
      email: this.email,
      bankCountry: this.bankCountry,
      bankInformation: this.additionalBankInfo,
      vatNumber: this.vatNumber
    };
  }

}
