<div class="container" style="max-width: 1000px;padding: 0rem 2rem;">
    <div class="row">
        <div class="mat-elevation-z8"
            style="max-width:100vw; font-family:Montserrat, sans-serif;border-radius:12px; background-color: #F7F8F8;">
            <div class="d-flex caption-container">
                <caption style="display: flex;width: 160px;margin-top: 5px;margin-right: 20px;">
                    <h3 style="margin-left: 5px;color: black;"><b>Devices</b></h3>
                </caption>
                <div class="device-btns">
                    <div>
                        <input type="file" accept=".csv" #fileInput (change)="onFileSelected($event)" style="display: none;">
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" (click)="fileInput.click()"
                            style="font-size: 11px;width: 150px;">Import CSV
                            File</button>
                    </div>
                    <!-- <div>
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal"
                            data-bs-target="#addDeviceByRangeModel" data-bs-dismiss="modal"
                            style="font-size: 11px;width: 150px;">Add Devices By Range</button>
                    </div> -->
                    <div>
                        <button aria-label="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addDeviceModel"
                            data-bs-dismiss="modal" (click)="checkActionType('create')"
                            style="font-size: 11px;width: 150px;">Add new device</button>
                    </div>
                </div>
                <div style="flex: 1; justify-content: flex-end; display: flex;">
                    <form class="form-inline d-flex">
                        <div class="form-group mx-sm-3" style="border-radius: 7px;border: 1px solid #727272;">
                            <input (change)="captureFilterData($event)" type="text" class="form-control"
                                placeholder="Search imei or name" style="font-size: 11px;">
                        </div>
                        <div>
                            <button aria-label="button" type="submit" class="btn btn-outline-primary btn-sm" (click)="filterData()"
                                style="width: 50px;font-size: 11px; margin-right: 10px;">Filter</button>
                        </div>
                    </form>
                </div>
            </div>
            <app-loader *ngIf="isLoading"></app-loader>
            <table mat-table [dataSource]="dataSource">
                <!-- <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef><b>No.</b></th>
                    <td mat-cell *matCellDef="let element;let i = index"><b>{{i+1}}</b></td>
                </ng-container> -->
        
                <ng-container matColumnDef="imei">
                    <th mat-header-cell *matHeaderCellDef><b>IMEI</b></th>
                    <td mat-cell *matCellDef="let element"><b>{{element.imei}}</b></td>
                </ng-container>
        
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef><b>Name</b></th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>
        
                <ng-container matColumnDef="companyName">
                    <th mat-header-cell *matHeaderCellDef><b>Company Name</b></th>
                    <td mat-cell *matCellDef="let element">{{element.companyName}}</td>
                </ng-container>
        
                <ng-container matColumnDef="imeiGroup">
                    <th mat-header-cell *matHeaderCellDef><b>Group</b></th>
                    <td mat-cell *matCellDef="let element">{{element.imeiGroup?.name}}</td>
                </ng-container>
        
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef><b>Actions</b></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon class="action_icon" (click)="checkActionType('update',element)" data-bs-toggle="modal"
                            data-bs-target="#addDeviceModel" style="color: #454545;">edit</mat-icon>
                        <mat-icon class="action_icon" data-bs-target="#deviceSettingsModel" data-bs-toggle="modal"
                            (click)="userDataPopulate(element)" style="color: #454545;">settings</mat-icon>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        
            <mat-paginator style="background-color: #F7F8F8;" [pageSizeOptions]="[10]" (page)="onPageChange($event)"
                showFirstLastButtons #paginator1>
            </mat-paginator>
        
        
            <!-- Add new Device Modal -->
            <div class="modal fade" id="addDeviceModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true" data-bs-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form [formGroup]="deviceForm" novalidate class="settingsForm">
                            <div class="modal-header">
                                <h5 class="modal-title" style="margin-left: 0.6rem;">{{modalHeaderText}}</h5>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="IMEI" formControlName="imei" required
                                        minlength="15" maxlength="15" [readonly]="modalButtonText === 'Update'">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Name" formControlName="name">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="Company Name"
                                        formControlName="companyName">
                                </div>
                                <div class="form-group">
                                    <select class="form-control" formControlName="deviceGroupId" required>
                                        <option value="0" disabled selected>Select a Device Group</option>
                                        <option [value]="group.id" *ngFor="let group of deviceGroups">{{ group.name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <button aria-label="button" type="submit" class="btn btn-outline-success" (click)="postDevice()"
                                    data-bs-dismiss="modal" [disabled]="!deviceForm.valid">{{modalButtonText}}</button>
                                <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                                    (click)="clearForm()">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
            <!-- Add Device by range Modal -->
            <div class="modal fade" id="addDeviceByRangeModel" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-bs-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <form [formGroup]="deviceByRangeForm" novalidate class="settingsForm">
                            <div class="modal-header">
                                <h5 class="modal-title" style="margin-left: 0.6rem;">Add Device By Range</h5>
                            </div>
                            <div class="modal-body">
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="From IMEI" formControlName="imeiFrom"
                                        required minlength="15" maxlength="15">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" type="text" placeholder="To IMEI" formControlName="imeiTo"
                                        required minlength="15" maxlength="15">
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <button aria-label="button" type="submit" class="btn btn-outline-success" (click)="postDeviceRange()"
                                    data-bs-dismiss="modal"
                                    [disabled]="!deviceByRangeForm.valid || (+deviceByRangeForm.value.imeiFrom >= +deviceByRangeForm.value.imeiTo)">Add</button>
                                <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                                    (click)="clearForm()">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
            <!-- Device Settings Modal -->
            <div class="modal fade" id="deviceSettingsModel" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-bs-backdrop="static">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content" style="width: 800px;">
                        <form novalidate class="settingsForm" style="width:800px;">
                            <div class="modal-header d-flex flex-column align-items-baseline">
                                <div>
                                    <h6 class="modal-title" style="margin-left: 0.6rem;font-size: 15px;">
                                        <b>{{deviceSettingHeader}}</b>
                                    </h6>
                                </div>
                                <div *ngIf="additionalData?.distributor">
                                    <h6 class="modal-title" style="margin-left: 0.6rem;font-size: 15px;">
                                        Distributor - {{additionalData?.distributor?.name}}</h6>
                                </div>
                                <div *ngIf="additionalData?.reseller">
                                    <h6 class="modal-title" style="margin-left: 0.6rem;font-size: 15px;">
                                        Reseller - {{additionalData?.reseller?.name}}</h6>
                                </div>
                                <div *ngIf="additionalData?.account">
                                    <h6 class="modal-title" style="margin-left: 0.6rem;font-size: 15px;">
                                        Account - {{additionalData?.account?.name}}</h6>
                                </div>
                                <div *ngIf="additionalData?.endUser">
                                    <h6 class="modal-title" style="margin-left: 0.6rem;font-size: 15px;">
                                        User - {{additionalData?.endUser?.name}}</h6>
                                </div>
                                <div class="d-flex justify-content-center align-items-center"
                                    *ngIf="additionalData?.isCorporate">
                                    <div style="margin-left: 0.6rem;font-size: 15px;">
                                        <mat-icon>business</mat-icon>
                                    </div>
                                    <div style="margin-left: 0.6rem; font-size: 15px;">
                                        <span>This is a Corporate Device</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center align-items-center" *ngIf="additionalData?.isRental">
                                    <div style="margin-left: 0.6rem;font-size: 15px;">
                                        <mat-icon>vpn_key</mat-icon>
                                    </div>
                                    <div style="margin-left: 0.6rem; font-size: 15px;">
                                        <span>This is a Rental Device</span>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-body">
                                <mat-tab-group *ngIf="!showTopUpModal">
                                    <mat-tab label="PURCHASE HISTORY" style="font-family: Montserrat, sans-serif;">
                                        <h6 class="d-flex justify-content-center mt-3" *ngIf="purchaseHistoryLoader">
                                            Loading.....
                                        </h6>
                                        <h6 class="d-flex justify-content-center mt-3"
                                            *ngIf="!purchaseHistoryLoader && purchaseHistoryData && purchaseHistoryData.length == 0">
                                            No history available !
                                        </h6>
                                        <table mat-table [dataSource]="purchaseHistoryData">
                                            <ng-container matColumnDef="plan">
                                                <th mat-header-cell *matHeaderCellDef><b>Name</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.bundleName}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="price">
                                                <th mat-header-cell *matHeaderCellDef><b>Price</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.bundlePrice}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="validity">
                                                <th mat-header-cell *matHeaderCellDef><b>Validity</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.bundleValidity}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="pd">
                                                <th mat-header-cell *matHeaderCellDef><b>Purchase Date</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.purchaseDate}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="sd">
                                                <th mat-header-cell *matHeaderCellDef><b>Start Date</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.startDate}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="ed">
                                                <th mat-header-cell *matHeaderCellDef><b>End Date</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.expiryDate}}</b></td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnPurchaseHostory"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnPurchaseHostory;"></tr>
                                        </table>
                                    </mat-tab>
                                    <mat-tab label="DATA USAGE">
                                        <h6 class="d-flex justify-content-center mt-3" *ngIf="dataUsageLoader">
                                            Loading.....
                                        </h6>
                                        <table mat-table [dataSource]="dataUsageSource">
                                            <!-- <ng-container matColumnDef="position">
                                                <th mat-header-cell *matHeaderCellDef><b>No.</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.position}}</b></td>
                                            </ng-container> -->
        
                                            <ng-container matColumnDef="dataAmount">
                                                <th mat-header-cell *matHeaderCellDef><b>Total Data Used</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.dataAmount}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="startDate">
                                                <th mat-header-cell *matHeaderCellDef><b>Start Date</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.startDate}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="endDate">
                                                <th mat-header-cell *matHeaderCellDef><b>End Date</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.endDate}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="visitedCountry">
                                                <th mat-header-cell *matHeaderCellDef><b>Country</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.visitedCountry}}</b></td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsDataUsage"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsDataUsage;"></tr>
                                        </table>
        
                                        <mat-paginator [pageSizeOptions]="[10]" [length]="58"
                                            (page)="onPageChangeDataUsage($event)" showFirstLastButton #paginator2>
                                        </mat-paginator>
                                    </mat-tab>
                                    <mat-tab label="DATA USAGE BY COUNTRY">
                                        <h6 class="d-flex justify-content-center mt-3" *ngIf="dataUsageByCountryLoader">
                                            Loading.....
                                        </h6>
                                        <table mat-table [dataSource]="dataUsageByCountrySource">
                                            <!-- <ng-container matColumnDef="position">
                                                <th mat-header-cell *matHeaderCellDef><b>No.</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.position}}</b></td>
                                            </ng-container> -->
        
                                            <ng-container matColumnDef="dataAmount">
                                                <th mat-header-cell *matHeaderCellDef><b>Total Data Used</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.dataAmount}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="visitedCountry">
                                                <th mat-header-cell *matHeaderCellDef><b>Country</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.visitedCountry}}</b></td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsDataUsageByCountry"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsDataUsageByCountry;"></tr>
                                        </table>
        
                                        <mat-paginator [pageSizeOptions]="[10]" (page)="onPageChangeDataUsageByCountry($event)"
                                            showFirstLastButton #paginator3>
                                        </mat-paginator>
                                    </mat-tab>
                                    <mat-tab label="DATA USAGE BY DAY">
                                        <h6 class="d-flex justify-content-center mt-3" *ngIf="dataUsageByDayLoader">
                                            Loading.....
                                        </h6>
                                        <table mat-table [dataSource]="dataUsageByDaySource">
                                            <!-- <ng-container matColumnDef="position">
                                                <th mat-header-cell *matHeaderCellDef><b>No.</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.position}}</b></td>
                                            </ng-container> -->
        
                                            <ng-container matColumnDef="dataAmount">
                                                <th mat-header-cell *matHeaderCellDef><b>Total Data Used</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.dataAmount}}</b></td>
                                            </ng-container>
        
                                            <ng-container matColumnDef="date">
                                                <th mat-header-cell *matHeaderCellDef><b>Date</b></th>
                                                <td mat-cell *matCellDef="let element"><b>{{element.startDate}}</b></td>
                                            </ng-container>
        
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnsDataUsageByDay"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnsDataUsageByDay;"></tr>
                                        </table>
        
                                        <mat-paginator [pageSizeOptions]="[10]" (page)="onPageChangeDataUsageByDay($event)"
                                            showFirstLastButton #paginator4>
                                        </mat-paginator>
                                    </mat-tab>
                                </mat-tab-group>
        
                                <!-- Top Up Modal -->
                                <div *ngIf="showTopUpModal" style="background-color:#F7F8F8;padding: 10px;border-radius: 10px;">
                                    <div class="container">
                                        <h4 class="d-flex justify-content-center" style="font-size: 18px;">TOP UP DEVICE</h4>
                                        <div class="row d-flex">
                                            <h6>From date</h6>
                                            <div class="col-md-3 m-0">
                                                <select class="form-select" [(ngModel)]="topUpModel.from.year"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="periodChanged(topUpModel)">
                                                    <option *ngFor="let year of years" [value]="year"
                                                        [selected]="topUpModel.from.year == year">{{year}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-3 m-0">
                                                <select class="form-select" [(ngModel)]="topUpModel.from.month"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="periodChanged(topUpModel)">
                                                    <option *ngFor="let month of months" [value]="month"
                                                        [selected]="topUpModel.from.month == month">{{month}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-3 m-0">
                                                <select class="form-select" [(ngModel)]="topUpModel.from.day"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="periodChanged(topUpModel)">
                                                    <option
                                                        *ngFor="let day of days[topUpModel.from.year + months[topUpModel.from.month-1]]"
                                                        [value]="day" [selected]="topUpModel.from.day == day">{{day}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="topUpModel.bundle=='Roamability_500M_DP'">
                                            <h6>To date</h6>
                                            <div class="col-md-3 m-0">
                                                <select class="form-select" [(ngModel)]="topUpModel.to.year"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="periodChanged(topUpModel)">
                                                    <option *ngFor="let year of years" [value]="year"
                                                        [selected]="topUpModel.to.year == year">{{year}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-3 m-0">
                                                <select class="form-select" [(ngModel)]="topUpModel.to.month"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="periodChanged(topUpModel)">
                                                    <option *ngFor="let month of months" [value]="month"
                                                        [selected]="topUpModel.to.month == month">{{month}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-3 m-0">
                                                <select class="form-select" [(ngModel)]="topUpModel.to.day"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (change)="periodChanged(topUpModel)">
                                                    <option
                                                        *ngFor="let day of days[topUpModel.to.year + months[topUpModel.to.month-1]]"
                                                        [value]="day" [selected]="topUpModel.to.day == day">{{day}}</option>
                                                </select>
                                            </div>
                                            <span class="mt-2">Total: {{totalAmountDue}}</span>
                                        </div>
                                        <div class="row">
                                            <h6>Bundle</h6>
                                            <select class="form-select" style="margin-left: 10px;width: 400px;"
                                                [(ngModel)]="topUpModel.bundle" [ngModelOptions]="{ standalone: true }">
                                                <option *ngFor="let bundle of bundles" [value]="bundle.code"
                                                    [selected]="topUpModel.bundle == bundle.code">
                                                    {{bundle.name}} ({{bundle.price}} EUR)</option>
                                            </select>
                                        </div>
                                        <div style="margin-left: 20px;">
                                            <button aria-label="button" type="submit" class="btn btn-outline-success"
                                                (click)="topUpDevice(topUpModel)"
                                                style="font-size: 11px;width: 80px;margin-right: 10px;">Top
                                                Up</button>
                                            <button aria-label="button" style="margin-left: 10px;" type="button" class="btn btn-outline-danger"
                                                (click)="showTopUpModal = false"
                                                style="font-size: 11px;width: 80px;">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <button aria-label="button" type="button" class="btn btn-outline-primary" (click)="showTopUpModal = true"
                                    style="width: 80px;font-size: 11px;">
                                    Top Up
                                </button>
                                <button aria-label="button" type="button" class="btn btn-outline-primary" (click)="markDevice(true)"
                                    *ngIf="isAdmin && !additionalData.isCorporate && !additionalData.isRental"
                                    style="width: 180px;font-size: 11px;">Mark Device as
                                    Corporate</button>
                                <button aria-label="button" type="button" class="btn btn-outline-primary" (click)="markDevice(false)"
                                    *ngIf="isAdmin && (additionalData.isCorporate || additionalData.isRental)"
                                    style="width: 200px;font-size: 11px;">Move Device to
                                    regular stock</button>
                                <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                                    (click)="clearDeviceSettingsModal()" style="width: 60px; font-size: 11px;">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
</div>