import { Component, OnInit, ViewChild } from '@angular/core';
import { Url } from '../../../_enums/url.enums';
import { RoleType } from '../_enums/role-type.enum';
import { Distributor } from './_classes/distributor';
import { Reseller } from './_classes/reseller';
import { Role } from './_classes/role';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../messages/message.service';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.css']
})
export class NetworkComponent implements OnInit {
  userData: any;
  openAccountForm: boolean;
  formIsClosed: boolean;
  public distributors: Distributor[];
  public resellers: Reseller[];
  public selectedEntity: any;
  user = RoleType.User;
  public ROLE_TYPE = RoleType;
  isAdmin: boolean;
  isReseller: boolean;
  isDistributor: boolean
  addDevicesModel: { from: number, to: number };
  openRange: boolean;
  typeMap: {};
  public openTopUp: boolean;
  public topUpModel: { amount };
  public rootEntityName: string;
  public rootEntityRole: string;
  public rootSelected: boolean;
  displayedColumns: string[] = ['from', 'to'];
  dataSource: any = new MatTableDataSource();
  imeiTableHeight = '30vh'
  tableContainerHeight = '90vh'

  renderResult: any = null;
  balanceAmount: any = 0

  // Form Controller
  distributorForm: FormGroup
  distributorHeaderText: string = 'CREATE DISTRIBUTOR'
  distributorButtonText: string = 'Create'

  resellerForm: FormGroup
  resellerHeaderText: string = 'CREATE RESELLER'
  resellerButtonText: string = 'Create'

  accountForm: FormGroup
  accountHeaderText: string = 'CREATE ACCOUNT'
  accountButtonText: string = 'Create'

  userForm: FormGroup
  userHeaderText: string = 'CREATE USER'
  userButtonText: string = 'Create'
  selectedUserType: number = 0 // 0 - Private ; 1 - Business

  additionalInfo: any = {}
  deviceByRangeForm: FormGroup

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private http: HttpClient, private fb: FormBuilder, private messageService: MessageService) {
    this.typeMap = {
      '0': 'Platinum',
      '1': 'Gold',
      '2': 'Silver',
      '3': 'Bronze'
    };

    this.deviceByRangeForm = this.fb.group({
      imeiFrom: ['', [Validators.required, Validators.pattern(/^[0-9]{15}$/)]],
      imeiTo: ['', [Validators.required, Validators.pattern(/^[0-9]{15}$/)]]
    })

    this.distributorForm = this.fb.group({
      companyName: ['', Validators.required],
      taxIdnf: ['', Validators.required],
      comission: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      contactPerson: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      bankCountry: ['', Validators.required],
      bankInfo: [''],
    })

    this.resellerForm = this.fb.group({
      companyName: ['', Validators.required],
      taxIdnf: ['', Validators.required],
      type: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      contactPerson: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      bankCountry: ['', Validators.required],
      bankInfo: [''],
    })

    this.accountForm = this.fb.group({
      companyName: ['', Validators.required],
      taxIdnf: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      contactPerson: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      bankCountry: ['', Validators.required],
      bankInfo: [''],
    })

    this.userForm = this.fb.group({
      companyName: ['', Validators.required],
      taxIdnf: ['', Validators.required],
      country: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', Validators.required],
      addressOne: ['', Validators.required],
      addressTwo: [''],
      contactPerson: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      bankCountry: ['', Validators.required],
      bankInfo: [''],
    })

  }

  ngOnInit(): void {
    this.initData()
    this.imeiTableHeight = '30vh'
    this.tableContainerHeight = '90vh'
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  private accountsDistributor(dbData) {
    const result = { resellers: undefined, distributors: undefined };
    if (JSON.parse(localStorage.getItem('user_info')).roles.indexOf(RoleType.Reseller) === -1) {
      const distributors: Distributor[] = [];

      dbData.forEach(distributorDTO => {
        const distributor = new Distributor(distributorDTO);
        distributors.push(distributor);
      });

      result.distributors = distributors;
    } else {
      const resellers: Reseller[] = [];

      dbData.forEach(resellerDTO => {
        const reseller = new Reseller(resellerDTO);
        resellers.push(reseller);
      });

      result.resellers = resellers;
    }
    return result;
  }

  getAccounts() {
    return this.http.get<{ data: Role[] }>(Url.WebPortalActualURL + 'all/network').pipe(
      map(allNetworkEntitiesResponse => this.accountsDistributor(allNetworkEntitiesResponse.data))
    );
  }

  private initData() {
    const userData = JSON.parse(localStorage.getItem('user_info'))
    this.userData = userData
    this.isAdmin = userData.isAdmin;
    this.isReseller = userData.roles.indexOf('RESELLER') !== -1
    this.isDistributor = userData.roles.indexOf('DISTRIBUTOR') !== -1
    this.rootEntityName = userData.firstName + ' ' + userData.lastName;
    this.rootEntityRole = userData.roles[0];
    this.getAccounts().subscribe(result => {
      if (userData.roles.indexOf(RoleType.Reseller) !== -1) {
        this.resellers = result.resellers;
        this.renderResult = this.resellers[0]
        this.dataSource = new MatTableDataSource(this.resellers[0].imeiRanges);
        this.imeiTableHeight = '40vh'
        this.tableContainerHeight = '145vh'
      } else {
        this.distributors = result.distributors;
        this.renderResult = this.distributors[0]
        this.dataSource = new MatTableDataSource(this.distributors[0].imeiRanges);
        this.imeiTableHeight = '40vh'
        this.tableContainerHeight = '145vh'
      }
      console.log("Init Data >>>>> ", this.resellers, this.distributors, this.isReseller, this.isDistributor, this.isAdmin)
      this.rootSelected = true;
    });

  }

  renderData(role, data) {
    this.renderResult = data
    this.dataSource = new MatTableDataSource(data.imeiRanges);
    this.imeiTableHeight = '40vh'
    this.tableContainerHeight = '145vh'
    console.log(data)
  }

  addDistributor(action, data = null) {
    if (action == 'create') {
      this.distributorHeaderText = 'CREATE DISTRIBUTOR'
      this.distributorButtonText = 'Create'
    } else if (action == 'edit') {
      this.distributorHeaderText = 'UPDATE DISTRIBUTOR'
      this.distributorButtonText = 'Update'
      this.additionalInfo = data
      this.distributorForm.patchValue({
        companyName: data?.companyName,
        taxIdnf: data?.vatNumber,
        comission: data?.commission,
        country: data?.country,
        city: data?.city,
        postalCode: data?.postalCode,
        addressOne: data?.address1,
        addressTwo: data?.address2 ? data?.address2 : "",
        contactPerson: data?.contactPerson,
        email: data?.email,
        bankCountry: data?.contactPerson,
        bankInfo: data?.additionalBankInfo ? data?.additionalBankInfo : "",
      })
    }
  }

  addReseller(action, data = null) {
    if (action == 'create') {
      this.resellerHeaderText = 'CREATE RESELLER'
      this.resellerButtonText = 'Create'
    } else {
      this.resellerHeaderText = 'EDIT RESELLER'
      this.resellerButtonText = 'Update'
      this.additionalInfo = data
      this.resellerForm.patchValue({
        companyName: data?.companyName,
        taxIdnf: data?.vatNumber,
        type: data?.type,
        country: data?.country,
        city: data?.city,
        postalCode: data?.postalCode,
        addressOne: data?.address1,
        addressTwo: data?.address2 ? data?.address2 : "",
        contactPerson: data?.contactPerson,
        email: data?.email,
        bankCountry: data?.contactPerson,
        bankInfo: data?.additionalBankInfo ? data?.additionalBankInfo : "",
      })
    }
  }

  addAccount(action, data = null) {
    if (action == 'create') {
      this.accountHeaderText = 'CREATE ACCOUNT'
      this.accountButtonText = 'Create'
    } else if (action == 'edit') {
      this.accountHeaderText = 'EDIT ACCOUNT'
      this.accountButtonText = 'Update'
      this.additionalInfo = data
      this.accountForm.patchValue({
        companyName: data?.companyName,
        taxIdnf: data?.vatNumber,
        country: data?.country,
        city: data?.city,
        postalCode: data?.postalCode,
        addressOne: data?.address1,
        addressTwo: data?.address2 ? data?.address2 : "",
        contactPerson: data?.contactPerson,
        email: data?.email,
        bankCountry: data?.contactPerson,
        bankInfo: data?.additionalBankInfo ? data?.additionalBankInfo : "",
      })
    }
  }
  addUser(action, data = null) {
    if (action == 'create') {
      this.userHeaderText = 'CREATE USER'
      this.userButtonText = 'Create'
    } else if (action == 'edit') {
      this.userHeaderText = 'EDIT USER'
      this.userButtonText = 'Update'
      this.additionalInfo = data
      this.userForm.patchValue({
        companyName: data?.companyName,
        taxIdnf: data?.vatNumber,
        country: data?.country,
        city: data?.city,
        postalCode: data?.postalCode,
        addressOne: data?.address1,
        addressTwo: data?.address2 ? data?.address2 : "",
        contactPerson: data?.contactPerson,
        email: data?.email,
        bankCountry: data?.contactPerson,
        bankInfo: data?.additionalBankInfo ? data?.additionalBankInfo : "",
      })
      this.selectedUserType = data.accountType == 'private' ? 0 : 1
    }
  }

  postDistributor() {
    const url = `${Url.WebPortalActualURL}create/distributor`
    let formData = this.distributorForm.value
    const payload = {
      "data": {
        "role": "DISTRIBUTOR",
        "name": formData.companyName,
        "country": formData.country,
        "city": formData.city,
        "postalCode": formData.postalCode,
        "addressLine": formData.addressOne,
        "contactPerson": formData.contactPerson,
        "email": formData.email,
        "bankCountry": formData.bankCountry,
        "vatNumber": formData.taxIdnf,
        "commission": formData.comission,
        "addressLine2": formData.addressTwo.length > 0 ? formData.addressTwo : null,
        "bankInformation": formData.bankInfo.length > 0 ? formData.bankInfo : null
      }
    }
    if (this.distributorButtonText == 'Update') {
      payload.data['id'] = this.additionalInfo.id
    }

    this.http.post<any>(url, payload).subscribe(res => {
      this.distributorButtonText == 'Update' ? this.messageService.alertSuccess('Updated Distributor') :
        this.messageService.alertSuccess('Created Distributor')
      this.initData()
      this.clearForm()
    }, (error) => {
      this.clearForm()
      this.messageService.alertError('Error')
    })
  }

  postAccount() {
    console.log(this.accountForm.value)
    const url = `${Url.WebPortalActualURL}create/account`
    let formData = this.accountForm.value
    const payload = {
      "data": {
        "role": "ACCOUNT",
        "name": formData.companyName,
        "country": formData.country,
        "city": formData.city,
        "postalCode": formData.postalCode,
        "addressLine": formData.addressOne,
        "contactPerson": formData.contactPerson,
        "email": formData.email,
        "bankCountry": formData.bankCountry,
        "vatNumber": formData.taxIdnf,
        "addressLine2": formData.addressTwo.length > 0 ? formData.addressTwo : null,
        "bankInformation": formData.bankInfo.length > 0 ? formData.bankInfo : null
      }
    }
    if (this.accountButtonText == 'Update') {
      payload.data['id'] = this.additionalInfo.id
    }

    this.http.post<any>(url, payload).subscribe(res => {
      this.accountButtonText == 'Update' ? this.messageService.alertSuccess('Updated Account') :
        this.messageService.alertSuccess('Created Account')
      this.initData()
      this.clearForm()
    }, (error) => {
      this.clearForm()
      this.messageService.alertError('Error')
    })
  }

  postReseller() {
    const url = `${Url.WebPortalActualURL}create/reseller`
    let formData = this.resellerForm.value
    const payload = {
      "data": {
        "role": "RESELLER",
        "name": formData.companyName,
        "country": formData.country,
        "city": formData.city,
        "postalCode": formData.postalCode,
        "addressLine": formData.addressOne,
        "contactPerson": formData.contactPerson,
        "email": formData.email,
        "bankCountry": formData.bankCountry,
        "vatNumber": formData.taxIdnf,
        "type": formData.type,
        "addressLine2": formData.addressTwo.length > 0 ? formData.addressTwo : null,
        "bankInformation": formData.bankInfo.length > 0 ? formData.bankInfo : null
      }
    }
    if (this.resellerButtonText == 'Update') {
      payload.data['id'] = this.additionalInfo.id
    }

    this.http.post<any>(url, payload).subscribe(res => {
      this.resellerButtonText == 'Update' ? this.messageService.alertSuccess('Updated Reseller') :
        this.messageService.alertSuccess('Created Reseller')
      this.initData()
      this.clearForm()
    }, (error) => {
      this.clearForm()
      this.messageService.alertError('Error')
    })
  }

  postUser() {
    const url = `${Url.WebPortalActualURL}create/enduser`
    let formData = this.userForm.value
    const payload = {
      "data": {
        "role": "USER",
        "name": formData.companyName,
        "country": formData.country,
        "city": formData.city,
        "postalCode": formData.postalCode,
        "addressLine": formData.addressOne,
        "contactPerson": formData.contactPerson,
        "email": formData.email,
        "bankCountry": formData.bankCountry,
        "vatNumber": formData.taxIdnf,
        "addressLine2": formData.addressTwo.length > 0 ? formData.addressTwo : null,
        "bankInformation": formData.bankInfo.length > 0 ? formData.bankInfo : null,
        "accountType": this.selectedUserType == 0 ? 'private' : 'business'
      }
    }
    if (this.userButtonText == 'Update') {
      payload.data['id'] = this.additionalInfo.id
    }

    this.http.post<any>(url, payload).subscribe(res => {
      this.userButtonText == 'Update' ? this.messageService.alertSuccess('Updated User') :
        this.messageService.alertSuccess('Created User')
      this.initData()
      this.clearForm()
    }, (error) => {
      this.clearForm()
      this.messageService.alertError('Error')
    })
  }

  clearForm() {
    this.additionalInfo = null
    this.distributorForm.setValue({
      companyName: '',
      taxIdnf: '',
      comission: '',
      country: '',
      city: '',
      postalCode: '',
      addressOne: '',
      addressTwo: '',
      contactPerson: '',
      email: '',
      bankCountry: '',
      bankInfo: '',
    })

    this.accountForm.setValue({
      companyName: '',
      taxIdnf: '',
      country: '',
      city: '',
      postalCode: '',
      addressOne: '',
      addressTwo: '',
      contactPerson: '',
      email: '',
      bankCountry: '',
      bankInfo: '',
    })

    this.resellerForm.setValue({
      type: '',
      companyName: '',
      taxIdnf: '',
      country: '',
      city: '',
      postalCode: '',
      addressOne: '',
      addressTwo: '',
      contactPerson: '',
      email: '',
      bankCountry: '',
      bankInfo: '',
    })

    this.userForm.setValue({
      companyName: '',
      taxIdnf: '',
      country: '',
      city: '',
      postalCode: '',
      addressOne: '',
      addressTwo: '',
      contactPerson: '',
      email: '',
      bankCountry: '',
      bankInfo: '',
    })

    this.deviceByRangeForm.setValue({
      imeiFrom: '',
      imeiTo: ''
    })

    this.balanceAmount = 0
  }

  postTopUp() {
    const url = `${Url.WebPortalActualURL}system/adminTopUpBalance`
    const payload = {
      "amount": parseInt(this.balanceAmount),
      "entityId": this.renderResult.id,
      "type": this.renderResult.role
    }
    this.http.post<any>(url, payload).subscribe(res => {
      this.messageService.alertSuccess('Updated Balance')
      this.initData()
      this.clearForm()
    }, (error) => {
      this.clearForm()
      this.messageService.alertError('Error')
    })
  }

  handleAccountBalance(event) {
    this.balanceAmount = event.target.value
  }

  postDeviceRange() {
    const url = `${Url.WebPortalActualURL}create/deviceAssignment`
    let balance = this.deviceByRangeForm.value
    let payload = {
      "data": [balance.imeiFrom, balance.imeiTo],
      "asigneeId": this.renderResult.id,
      "asigneeRole": this.renderResult.role
    }
    this.http.post<any>(url, payload).subscribe(res => {
      this.messageService.alertSuccess('Created Devices')
      this.initData()
      this.clearForm()
    }, (error) => {
      this.clearForm()
      this.messageService.alertError('Error')
    })
  }

  onFileSelected(event: any) {
    const url = `${Url.WebPortalActualURL}create/deviceAssignment`
    const file = event.target.files[0];
    let arr = []; let rows = []
    if (file) {
      // Check the file extension to see if it's a CSV file
      const fileName = file.name;
      const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

      if (fileExtension.toLowerCase() !== 'csv') {
        this.messageService.alertError('Please upload a CSV File');
        return; // Exit the function if it's not a CSV file
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileContent = e.target.result;
        rows = fileContent.split(/\r?\n/);
        if (rows[0] == 'IMEI') {
          for (let i = 1; i <= rows.length - 1; i++) {
            arr.push(parseInt(rows[i]))
          }
        }
        let payload = {
          "data": arr,
          "asigneeId": this.renderResult.id,
          "asigneeRole": this.renderResult.role
        }
        this.http.post<any>(url, payload).subscribe(res => {
          this.messageService.alertSuccess('Created Devices')
          this.initData()
        }, (error) => {
          this.messageService.alertError('Error')
        })
      };
      reader.readAsText(file);
    }
  }

}
