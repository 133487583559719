<div class="container mt-4" style="max-width: 1000px;font-family:Montserrat, sans-serif;">
    <mat-button-toggle-group style="margin-bottom: 12px;" class="small-button-toggle-group">
        <mat-button-toggle class="center-align" [value]="0" [checked]="true" (click)="toggleView(0)" matTooltip="Grid View">
            <mat-icon style="font-size: 25px;color: #454545;">grid_on</mat-icon> <!-- Replace 'format_bold' with your desired icon name -->
        </mat-button-toggle>
        <mat-button-toggle class="center-align" [value]="1" (click)="toggleView(1)" matTooltip="List View">
            <mat-icon style="font-size: 25px;color: #454545;">list_alt</mat-icon> <!-- Replace 'format_italic' with your desired icon name -->
        </mat-button-toggle>
    </mat-button-toggle-group>
    <!-- Search bar and Add Device button -->
    <div class="row mb-3">
        <div class="col-md-10">
            <div class="input-group">
                <input style="font-size: 14px;border: 1px solid #727272;" class="form-control" type="text"
                    placeholder="Search Users" (keyup)="onSearch($event)">
                <span class="input-group-text" style="border: 1px solid #727272;background: #ffff;">
                    <mat-icon class="center-align" style="font-size: 23px;color:#727272;">search</mat-icon>
                </span>
            </div>
        </div>
        <div class="col-md-2">
            <button aria-label="button" type="button" class="center-align cs-bg-button" data-bs-toggle="modal"
                data-bs-target="#addDeviceModel" (click)="checkActionType('create')">
                <mat-icon style="font-size: 17px;display: flex;align-items: center;">add</mat-icon>
                <span class="center-align" style="font-size: 14px;">Add Bundle</span>
            </button>
        </div>
    </div>

    <!-- List of device cards -->
    <app-loader *ngIf="isLoading"></app-loader>
    <div class="row" *ngIf="toggleVal == 0">
        <div class="col-md-4" *ngFor="let bundle of filterBundle">
            <mat-card class="mt-2 custom-card">
                <mat-card-content>
                    <h6 class="card-text"><b>{{ removeHtml(bundle.name) }}</b></h6>
                </mat-card-content>
                <mat-card-content class="mb-0">
                    <p class="card-text text-secondary">
                        <strong>Price : </strong> {{ bundle.price + ' ' }}<span class="currency">{{ bundle.currency
                            }}</span>
                    </p>
                </mat-card-content>
                <mat-card-content class="mb-0">
                    <p class="card-text text-secondary">
                        <strong>Item Code : </strong> {{ bundle.exactItemCode }}
                    </p>
                </mat-card-content>
                <mat-card-content>
                    <p class="card-text text-secondary">
                        <strong>Bundle Code : </strong> {{ bundle.bundleCode }}
                    </p>
                </mat-card-content>
                <mat-card-actions>
                    <button aria-label="button" style="margin-left: 8px;" class="center-align btn btn-outline-primary btn-sm" data-bs-toggle="modal"
                        data-bs-target="#addDeviceModel" (click)="checkActionType('edit',bundle)">
                        <!-- <mat-icon style="font-size: 15px;display: flex;align-items: center;">edit</mat-icon> -->
                        <span class="center-align" style="font-size: 11px;font-family:Montserrat, sans-serif;">Edit</span>
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

    <mat-table style="font-family: Montserrat, sans-serif;" [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="toggleVal == 1">
        <!-- Define the columns -->
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef class="name-column">
                <b>Name</b>
                <mat-icon style="cursor: pointer;" *ngIf="sortDirection == 0" (click)="onSort(1)">arrow_drop_up</mat-icon>
                <mat-icon style="cursor: pointer;" *ngIf="sortDirection == 1" (click)="onSort(0)">arrow_drop_down</mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let bundle" class="name-column"><b>{{ removeHtml(bundle.name) }}</b></mat-cell>
        </ng-container>

        <ng-container matColumnDef="bundleCode">
            <mat-header-cell *matHeaderCellDef class="bundleCode-column"><b>Bundle Code</b></mat-header-cell>
            <mat-cell *matCellDef="let bundle" class="bundleCode-column">{{ bundle.bundleCode }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="itemCode">
            <mat-header-cell *matHeaderCellDef class="itemCode-column"><b>Item Code</b></mat-header-cell>
            <mat-cell *matCellDef="let bundle" class="itemCode-column">{{ bundle.exactItemCode }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef class="price-column"><b>Price</b></mat-header-cell>
            <mat-cell *matCellDef="let bundle" class="price-column">
                <div class="price-content">
                    <span>{{ bundle.price }}</span>
                    <span class="currency">{{ bundle.currency }}</span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="actions-column"><b>Actions</b></mat-header-cell>
            <mat-cell *matCellDef="let bundle" class="actions-column">
                <button aria-label="button" mat-icon-button data-bs-toggle="modal" data-bs-target="#addDeviceModel"
                    (click)="checkActionType('edit', bundle)">
                    <mat-icon style="font-size: 18px;color: #454545;">edit</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <!-- Set the table rows -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>


    <!-- Modal -->
    <div class="modal fade" id="addDeviceModel" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <form [formGroup]="bundleForm" novalidate class="settingsForm">
                    <div class="modal-header">
                        <h5 class="modal-title" style="margin-left: 10px;">{{modalHeaderText}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="Name" formControlName="name" required
                                minlength="1">
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="number" placeholder="Price" formControlName="price"
                                required>
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="Currency" formControlName="currency"
                                required>
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="Exact Item Code"
                                formControlName="exactItemCode" required>
                        </div>
                        <div class="form-group">
                            <input class="form-control" type="text" placeholder="UCloud Bundle Code"
                                formControlName="ucloudBundleCode" required>
                        </div>
                        <div class="form-group">
                            <select class="form-control" formControlName="deviceGroup" required>
                                <option value="" disabled selected>Select a Device Group</option>
                                <option [value]="group.id" *ngFor="let group of deviceGroups">{{ group.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer p-1">
                        <button aria-label="button" type="submit" class="btn btn-outline-success" (click)="postBundle()"
                            data-bs-dismiss="modal" [disabled]="!bundleForm.valid">{{modalButtonText}}</button>
                        <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                            (click)="clearForm()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>