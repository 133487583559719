<!-- TFA Modal -->
<div class="modal fade" id="tfaChainModal" tabindex="-1" aria-labelledby="tfaChainModalLabel" aria-hidden="true"
    data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px;">
        <div class="modal-content">
            <div class="modal-header" style="margin-left: 10px;">
                <h5 *ngIf="tfaChain.id === '-1'">ADD TFA CHAIN</h5>
                <h5 *ngIf="tfaChain.id !== '-1'">EDIT TFA CHAIN</h5>
            </div>
            <div class="modal-body">
                <form #addDeviceForm="ngForm">
                    <div class="mb-3">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" id="name" name="name" [(ngModel)]="tfaChain.name" class="form-control"
                            autocomplete="off" required placeholder="Enter TFA Chain Name">
                    </div>
                    <div class="mb-3">
                        <label for="trigger" class="form-label">Trigger</label>
                        <select id="trigger" [(ngModel)]="tfaChain.trigger.id" (change)="triggerChanged()"
                            name="trigger" class="form-select" required style="cursor: pointer;">
                            <option value="-1" disabled>Select Trigger</option>
                            <option *ngFor="let trigger of triggersList" [value]="trigger.id">{{trigger.name}}</option>
                        </select>
                    </div>
                    <div class="mb-3 row" *ngIf="selectedTrigger && (selectedTrigger.filters.length > 0)"
                        style="background-color:#F7F8F8;padding: 10px;border-radius: 5px;">
                        <div class="row">
                            <span class="text-primary">FILTER TRIGGER VALUE</span>
                        </div>
                        <div class="row d-flex align-items-center"
                            *ngFor="let filterValue of tfaChain.filterValues; let i = index; trackBy:trackByFn">
                            <div class="col-md-3">
                                <label for="trigger" class="form-label">Filter</label>
                                <select [(ngModel)]="filterValue.id"
                                    (change)="filterChanged(filterValue, selectedTrigger)" name="filterId-{{i}}"
                                    class="form-select" required>
                                    <option *ngFor="let filter of selectedTrigger.filters" [value]="filter.id">
                                        {{filter.propertyName}}</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <label for="trigger" class="form-label">Trigger</label>
                                <select [(ngModel)]="filterValue.comparatorId" name="comparatorId-{{i}}"
                                    class="form-select" required>
                                    <option *ngFor="let comparator of filterValue.comparators" [value]="comparator.id">
                                        {{comparatorCaptionMap[comparator.type]}}</option>
                                </select>
                            </div>
                            <div class="col-md-2">
                                <label for="trigger" class="form-label">Value</label>
                                <input type="text" *ngIf="!filterValue.dataType" disabled class="form-control">
                                <input type="text" *ngIf="filterValue.dataType==='STRING'"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="filterValue.value"
                                    class="form-control">
                                <input type="number" *ngIf="filterValue.dataType==='INTEGER'"
                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="filterValue.value"
                                    class="form-control">
                            </div>
                            <div class="col-md-2">
                                <!-- <button aria-label="button" type="button" class="btn btn-danger" (click)="deleteFilterValue(filterValue)"
                                    *ngIf="tfaChain.filterValues.length > 1">Delete</button> -->
                                <button aria-label="button" type="button" class="center-align btn btn-outline-danger" (click)="deleteFilterValue(filterValue)"
                                    *ngIf="tfaChain.filterValues.length > 1">
                                    <mat-icon
                                        style="font-size: 17px;display: flex;align-items: center;">delete</mat-icon>
                                    <span class="center-align" style="font-size: 14px;">Delete</span>
                                </button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center">
                            <button aria-label="button" style="width: 400px;" type="button" class="btn btn-outline-success"
                                (click)="addFilterValue()">Add Filter Value</button>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="action" class="form-label">Action</label>
                        <select id="action" [(ngModel)]="tfaChain.action.id" name="action" (change)="actionChanged()"
                            class="form-select" required style="cursor: pointer;">
                            <option value="-1" disabled>Select Action</option>
                            <option *ngFor="let action of actionsList" [value]="action.id">{{action.name}}</option>
                        </select>
                    </div>
                    <div class="mb-3" *ngIf="selectedAction"
                        style="background-color:#F7F8F8;padding: 10px;border-radius: 5px;">
                        <div class="action-parameter-container" *ngFor="let parameter of selectedAction.parameters">
                            <div class="mb-3">
                                <label for="param-{{parameter.id}}" class="form-label">{{parameter.name}}</label>
                                <input id="param-{{parameter.id}}" type="text" *ngIf="parameter.type==='STRING'"
                                    [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="tfaChain.action.parameterValues[parameter.id]" class="form-control">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button aria-label="button" type="submit" class="btn btn-outline-success" *ngIf="tfaChain.id === '-1'"
                    [disabled]="addDeviceForm.invalid" (click)="addTfaChain()" data-bs-dismiss="modal">Add</button>
                <button aria-label="button" type="submit" class="btn btn-outline-success" *ngIf="tfaChain.id !== '-1'"
                    [disabled]="addDeviceForm.invalid" (click)="addTfaChain()" data-bs-dismiss="modal">Update</button>
                <button aria-label="button" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"
                    (click)="closeForm()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<app-loader *ngIf="isLoading"></app-loader>
<div class="container" style="max-width: 1000px;">
    <main *ngIf="!isLoading">
        <header>
            <button aria-label="button" type="button" class="center-align cs-bg-button" data-bs-toggle="modal" data-bs-target="#tfaChainModal"
                (click)="showAddTfaChain()">
                <mat-icon
                    style="font-size: 17px;display: flex;align-items: center;">control_point_duplicate_rounded</mat-icon>
                <span class="center-align" style="font-size: 14px;">Add TFA Chain</span>
            </button>
        </header>
        <section>
            <div class="chain-container" *ngFor="let chain of tfaChains">
                <h4 style="margin-bottom: 20px;"><b>{{chain.name}}</b></h4>
                <!-- <div class="chain-stage-background trigger">
                    <span class="stage-name">TRIGGER</span>
                </div>
                <div class="chain-stage-background filter">
                    <span class="stage-name">FILTER</span>
                </div>
                <div class="chain-stage-background action">
                    <span class="stage-name">ACTION</span>
                </div> -->
                <div class="chain-stage" style="margin-left: 3gpx;">
                    <h6 style="color: rgb(52, 51, 51);font-weight: 400;">{{chain.trigger.name}}</h6>
                </div>
                <div class="chain-stage">
                    <!--<h6>Filters:</h6>-->
                    <div class="filter-description" *ngFor="let filter of chain.filters">
                        <span style="color: rgb(52, 51, 51);font-weight: 400;">
                            {{filter.propertyName}} {{filter.comparator}} {{filter.value}}
                        </span>
                    </div>
                </div>
                <div class="chain-stage">
                    <!--<h6>Action:{{chain.action.actionName}}</h6>-->
                    <div class="action-params" *ngFor="let parameter of chain.action.parameters">
                        <span style="color: rgb(52, 51, 51);font-weight: 400;">
                            {{parameter.name}} : {{parameter.value}}
                        </span>
                    </div>
                </div>
                <div class="actions">
                    <!-- <button aria-label="button" type="submit" class="btn btn-outline-success" data-bs-toggle="modal"
                        data-bs-target="#tfaChainModal" (click)="editChain(chain)">Edit</button> -->
                    <button aria-label="button" type="button" class="center-align btn btn-outline-primary" data-bs-toggle="modal"
                        data-bs-target="#tfaChainModal" (click)="editChain(chain)">
                        <!-- <mat-icon style="font-size: 17px;display: flex;align-items: center;">edit</mat-icon> -->
                        <span class="center-align" style="font-size: 11px;">Edit</span>
                    </button>
                    <button aria-label="button" type="button" class="center-align btn btn-outline-danger" (click)="deleteChain(chain)">
                        <!-- <mat-icon style="font-size: 17px;display: flex;align-items: center;">delete</mat-icon> -->
                        <span class="center-align" style="font-size: 11px;">Delete</span>
                    </button>
                </div>
            </div>
        </section>
    </main>
</div>