import { Component, OnInit } from '@angular/core';
import { Url } from '../../../_enums/url.enums';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../messages/message.service';

@Component({
  selector: 'app-settings-device-groups',
  templateUrl: './settings-device-groups.component.html',
  styleUrls: ['./settings-device-groups.component.css']
})
export class SettingsDeviceGroupsComponent implements OnInit {
  isLoading = true
  deviceGroupList: any = []
  filterDeviceGroup: any = []
  newDeviceGroup = ""
  editDeviceGroup = ""
  editDeviceId = null
  constructor(private http: HttpClient, private messageService:MessageService) { }

  ngOnInit(): void {
    this.getDeviceGroup()
  }

  getDeviceGroup() {
    const url = `${Url.WebPortalActualURL}all/deviceGroup/`;
    this.http.get<any>(url).subscribe(res => {
      const filterData = []
      for (let device of res?.data) {
        if (device.name !== ' ') {
          filterData.push(device)
        }
      }
      this.deviceGroupList = filterData
      this.filterDeviceGroup = filterData
      this.isLoading = false
    }, (error) => {
      this.isLoading = false
      console.log(error)
    });
  }

  onSearch(event) {
    let text = event.target.value
    if (text == '') {
      this.filterDeviceGroup = this.deviceGroupList
    } else {
      const arr = []
      this.filterDeviceGroup = this.deviceGroupList
      for (let obj of this.filterDeviceGroup) {
        if (obj.name.toUpperCase().includes(text.toUpperCase())) {
          arr.push(obj)
        }
      }
      this.filterDeviceGroup = arr
    }
  }

  postNewGroup(){
    const url = `${Url.WebPortalActualURL}create/deviceGroup`;
    const body = {
      data : {
        name : this.newDeviceGroup
      }
    }
    this.http.post<any>(url,body).subscribe(res => {
      this.messageService.alertSuccess('New Device Group Created')
      this.getDeviceGroup()
    }, (error) => {
      this.messageService.alertError('Error')
    });
  }

  clearInput(){
    this.newDeviceGroup = ""
  }

  populateModel(device){
    this.editDeviceGroup = device.name
    this.editDeviceId = device.id
  }

  postEditGroup(){
    const url = `${Url.WebPortalActualURL}create/deviceGroup`;
    const body = {
      data : {
        id : this.editDeviceId,
        name : this.editDeviceGroup
      }
    }
    this.http.post<any>(url,body).subscribe(res => {
      this.messageService.alertSuccess('Edited Device Group')
      this.getDeviceGroup()
    }, (error) => {
      this.messageService.alertError('Error')
    });
  }

}
