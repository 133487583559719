import { Component, OnInit } from '@angular/core';
import { Url } from '../../../_enums/url.enums';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../messages/message.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-settings-bundles',
  templateUrl: './settings-bundles.component.html',
  styleUrls: ['./settings-bundles.component.css']
})
export class SettingsBundlesComponent implements OnInit {
  isLoading = true
  bundles = []
  filterBundle = []
  modalButtonText = 'Create'
  modalHeaderText = 'Create Bundle'
  idGroup = null

  bundleForm: FormGroup;
  deviceGroups: any = [];

  displayedColumns: string[] = ['name','bundleCode','itemCode','price','actions'];
  dataSource: any = new MatTableDataSource();

  toggleVal = 0

  sortDirection = 0

  constructor(private http: HttpClient, private fb: FormBuilder, private messageService: MessageService) {
    this.bundleForm = this.fb.group({
      name: ['', Validators.required],
      price: [0, Validators.required],
      currency: ['', Validators.required],
      exactItemCode: ['', Validators.required],
      ucloudBundleCode: ['', Validators.required],
      deviceGroup: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.getDeviceGroup()
    this.getBundle()
  }

  getBundle() {
    const url = `${Url.WebPortalActualURL}all/bundleInformation/`;
    const url1 = `${Url.WebPortalActualURL}all/bundleInformation/?page=1`;
    const url2 = `${Url.WebPortalActualURL}all/bundleInformation/?page=2`
    let fullData = []
    this.http.get<any>(url).subscribe(res => {fullData.push(...res?.data)});
    this.http.get<any>(url1).subscribe(res => {
      fullData.push(...res?.data)
      this.isLoading = false
    });
    this.http.get<any>(url2).subscribe(res => {fullData.push(...res?.data)});
    this.bundles = fullData
    this.filterBundle = fullData
    this.dataSource = new MatTableDataSource(fullData);
  }

  onSearch(event) {
    let text = event.target.value
    if (text == '') {
      this.filterBundle = this.bundles
      this.dataSource = new MatTableDataSource(this.bundles);
    } else {
      const arr = []
      this.filterBundle = this.bundles
      for (let obj of this.filterBundle) {
        if (obj.name.toUpperCase().includes(text.toUpperCase())) {
          arr.push(obj)
        }
      }
      this.filterBundle = arr
      this.dataSource = new MatTableDataSource(arr);
    }
  }

  onSort(val) {
    this.sortDirection = val
    let arr = this.filterBundle
    arr.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (val == 0) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
    console.log(val,arr)
    this.filterBundle = arr
    this.dataSource = new MatTableDataSource(arr);
  }

  getDeviceGroup() {
    const url = `${Url.WebPortalActualURL}all/deviceGroup/`;
    this.http.get<any>(url).subscribe(res => {
      const filterData = []
      for (let device of res?.data) {
        if (device.name !== ' ') {
          filterData.push(device)
        }
      }
      this.deviceGroups = filterData
    }, (error) => {
      console.log(error)
    });
  }

  computeDeviceNameFromId(id) {
    for (let device of this.deviceGroups) {
      if (device.id == id) {
        return device.name
      }
    }
  }

  checkActionType(action, data = null) {
    if (action == 'create') {
      this.modalButtonText = 'Create'
      this.modalHeaderText = 'Create Bundle'
    } else if (action == 'edit') {
      this.modalButtonText = 'Update'
      this.modalHeaderText = 'Edit Bundle'
      this.bundleForm.setValue({
        name: data.name,
        price: data.price,
        currency: data.currency,
        exactItemCode: data.exactItemCode,
        ucloudBundleCode: data.bundleCode,
        deviceGroup: data.deviceGroupId,
      })
      this.idGroup = data.id
      console.log(data)
    }
  }

  clearForm() {
    this.bundleForm.setValue({
      name: '',
      price: 0,
      currency: '',
      exactItemCode: '',
      ucloudBundleCode: '',
      deviceGroup: '',
    })
    this.idGroup = null
  }

  postBundle() {
    const url = `${Url.WebPortalActualURL}create/bundleInformation`;
    let body = {}
    if(this.idGroup !== null){
      body = {
        data: {
          "id": this.idGroup,
          "name": this.bundleForm.value.name,
          "price": this.bundleForm.value.price,
          "currency": this.bundleForm.value.currency,
          "exactItemCode": this.bundleForm.value.exactItemCode,
          "bundleCode": this.bundleForm.value.ucloudBundleCode,
          "deviceGroupId": parseInt(this.bundleForm.value.deviceGroup)
        },
        imeiGroupId: parseInt(this.bundleForm.value.deviceGroup)
      }
    }else{
      body = {
        data: {
          "name": this.bundleForm.value.name,
          "price": this.bundleForm.value.price,
          "currency": this.bundleForm.value.currency,
          "exactItemCode": this.bundleForm.value.exactItemCode,
          "bundleCode": this.bundleForm.value.ucloudBundleCode,
          "deviceGroupId": parseInt(this.bundleForm.value.deviceGroup)
        },
        imeiGroupId: parseInt(this.bundleForm.value.deviceGroup)
      }
    }
    this.http.post<any>(url, body).subscribe(res => {
      this.idGroup !== null ? this.messageService.alertSuccess('Bundle Edited') : 
      this.messageService.alertSuccess('New Bundle Created')
      this.getBundle()
      this.clearForm()
    }, (error) => {
      this.messageService.alertError('Error')
    });
  }

  removeHtml(str:string){
    return str.replace('<br/>',' - ')
  }

  toggleView(val){
    this.toggleVal = val
  }

}
