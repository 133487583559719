import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router'; // Import the Router service

@Injectable()
export class AuthInterseptor implements HttpInterceptor {
  constructor(private router: Router) {} // Inject the Router service

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.url.includes('login')) {
      return next.handle(request);
    }

    const urlWithSlash = request.url.endsWith('/') || request.url.includes('all/devices/?') || request.url.includes('all/bundleInformation') ? request.url : request.url + '/';
    const modifiedRequest = request.clone({
      url: urlWithSlash,
      setHeaders: {
        'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    });

    return next.handle(modifiedRequest).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
          this.router.navigate(['']);
          localStorage.clear();
        }
        return throwError(error);
      })
    );
  }
}
