import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class AlertService {
  message: string;
  displayProp:string = 'none'
  confirmBool:boolean = false
  checkboxBool:boolean = false
  showOption:boolean = true
  private confirmSubject = new Subject<any>();

  openAlert(message:string,option:boolean){
    setTimeout(()=>{
        this.message = message
        this.displayProp = 'flex'
        this.showOption = option
    },200)
  }

  confirmAction(bool: boolean) {
    this.displayProp = 'none';
    this.confirmSubject.next([bool,this.checkboxBool]);
    this.checkboxBool = false
  }

  getConfirmResult() {
    return this.confirmSubject.asObservable();
  }

}
