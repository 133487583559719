import { RoleType } from '../../_enums/role-type.enum';
import { IServerRole } from '../../_interfaces/server.role.interface';
import { Role } from './role';

export class User extends Role {
  accountId: number;
  accountType: string;
  constructor(data?: IServerRole) {
    super(data);
    this.role = RoleType.User;
    this.accountType = (data && data.accountType) || 'private';
  }
}
