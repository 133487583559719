import { Component, OnInit } from '@angular/core';
import { Url } from '../../../_enums/url.enums';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../messages/message.service';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-settings-users',
  templateUrl: './settings-users.component.html',
  styleUrls: ['./settings-users.component.css']
})
export class SettingsUsersComponent implements OnInit {
  isLoading = true
  usersList = []
  distributorList = []
  resellerList = []
  filterUsersList = []
  modalHeaderText = 'Create User'
  modalButtonText = 'Create'
  selectedUserType = 2 // Distributor = 0 , Reseller = 1 , Admin = 2 
  additionalEditData = []
  roleInfo = {
    "country": "Spain",
    "role": "DISTRIBUTOR",
    "city": "Madrid",
    "bankInformation": null,
    "postalCode": "28003",
    "contactPerson": "Diego Lopez Salazar",
    "bankCountry": "Spain",
    "balance": 24.0,
    "isRental": null,
    "name": "New Ignition Business",
    "addressLine1": "C/ Maudes 51 ",
    "addressLine2": "8a Pta",
    "commission": 25,
    "id": 25,
    "email": "diego@wifimotion.com",
    "vatNumber": "B86777547"
  }
  userForm: FormGroup;

  displayedColumns: string[] = ['name', 'role', 'username', 'details', 'actions'];
  dataSource: any = new MatTableDataSource();

  toggleVal = 0

  sortDirection = 0

  constructor(private http: HttpClient, private fb: FormBuilder, private messageService: MessageService) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      password: [''],
      distributorId: [null],
      resellerId: [null]
    });
  }

  ngOnInit(): void {
    this.getUsers()
    this.getDistributor()
    this.getReseller()
  }

  onSearch(event) {
    let text = event.target.value
    if (text == '') {
      this.filterUsersList = this.usersList
      this.dataSource = new MatTableDataSource(this.usersList);
    } else {
      const arr = []
      this.filterUsersList = this.usersList
      for (let obj of this.filterUsersList) {
        let name = obj.firstName + obj.lastName
        if (name.toUpperCase().includes(text.toUpperCase())) {
          arr.push(obj)
        }
      }
      this.filterUsersList = arr
      this.dataSource = new MatTableDataSource(arr);
    }
  }

  onSort(val) {
    this.sortDirection = val
    let arr = this.filterUsersList
    arr.sort((a, b) => {
      const nameA = a.firstName.toLowerCase();
      const nameB = b.firstName.toLowerCase();
      if (val == 0) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
    this.filterUsersList = arr
    this.dataSource = new MatTableDataSource(arr);
  }

  getUsers() {
    const url = `${Url.WebPortalActualURL}all/user/`;
    this.http.get<any>(url).subscribe(res => {
      this.usersList = res.data
      this.filterUsersList = res.data
      this.dataSource = new MatTableDataSource(res?.data);
      this.isLoading = false
    }, (error) => {
      this.isLoading = false
    });
  }

  getDistributor() {
    const url = `${Url.WebPortalActualURL}all/distributor/`;
    this.http.get<any>(url).subscribe(res => {
      this.distributorList = res.data
    }, (error) => {
      console.log(error)
    });
  }

  getReseller() {
    const url = `${Url.WebPortalActualURL}all/reseller/`;
    this.http.get<any>(url).subscribe(res => {
      this.resellerList = res.data
    }, (error) => {
      console.log(error)
    });
  }

  emitDetails(user: any) {
    if (user.roles[0] == 'DISTRIBUTOR') {
      this.roleInfo = user.distributor
    } else {
      this.roleInfo = user.reseller
    }
  }

  checkActionType(action, data = null) {
    if (action == 'create') {
      this.modalHeaderText = 'Create User'
      this.modalButtonText = 'Add'
    } else {
      console.log(data)
      this.modalHeaderText = 'Edit User'
      this.modalButtonText = 'Update'
      this.userForm.patchValue({
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        distributorId: data.distributorId,
        resellerId: data.resellerId
      })
      if (data.distributorId !== null) {
        this.selectedUserType = 0
        this.additionalEditData.push(data.distributor), this.additionalEditData.push(data.id)
      }
      else if (data.resellerId !== null) {
        this.selectedUserType = 1,
          this.additionalEditData.push(data.reseller), this.additionalEditData.push(data.id)
      }
      else {
        this.selectedUserType = 2
        this.additionalEditData.push(null), this.additionalEditData.push(data.id)
      }
    }
  }

  handleRole(type) {
    this.selectedUserType = type
  }

  clearForm() {
    this.userForm.setValue({
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      distributorId: null,
      resellerId: null
    })
    this.selectedUserType = 2,
      this.additionalEditData = []
  }

  postRequest() {
    let payload = {}
    if (this.modalButtonText == 'Add') {
      let data = this.userForm.value
      if (this.selectedUserType == 0) {
        payload = {
          "data": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "username": data.username,
            "resellerId": data.resellerId,
            "distributorId": data.distributorId,
            "password": data.password,
            "roles": [
              "DISTRIBUTOR"
            ],
            "isAdmin": false,
            "admin": false
          }
        }
      } else if (this.selectedUserType == 1) {
        payload = {
          "data": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "username": data.username,
            "resellerId": data.resellerId,
            "distributorId": data.distributorId,
            "password": data.password,
            "roles": [
              "RESELLER"
            ],
            "isAdmin": false,
            "admin": false
          }
        }
      } else {
        payload = {
          "data": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "username": data.username,
            "resellerId": data.resellerId,
            "distributorId": data.distributorId,
            "password": data.password,
            "roles": [
              "ADMIN"
            ],
            "isAdmin": true,
            "admin": true
          }
        }
      }
    } else if (this.modalButtonText == 'Update') {
      let data = this.userForm.value
      if (this.selectedUserType == 0) {
        payload = {
          "data": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "username": data.username,
            "resellerId": null,
            "distributorId": data.distributorId,
            "password": data.password,
            "roles": [
              "DISTRIBUTOR"
            ],
            "isAdmin": false,
            "admin": false,
            "distributor": this.additionalEditData[0],
            "id": this.additionalEditData[1]
          }
        }
      } else if (this.selectedUserType == 1) {
        payload = {
          "data": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "username": data.username,
            "resellerId": data.resellerId,
            "distributorId": null,
            "password": data.password,
            "roles": [
              "RESELLER"
            ],
            "isAdmin": false,
            "admin": false,
            "reseller": this.additionalEditData[0],
            "id": this.additionalEditData[1]
          }
        }
      } else {
        payload = {
          "data": {
            "firstName": data.firstName,
            "lastName": data.lastName,
            "username": data.username,
            "resellerId": null,
            "distributorId": null,
            "password": data.password,
            "roles": [
              "ADMIN"
            ],
            "isAdmin": true,
            "admin": true,
            "id": this.additionalEditData[1]
          }
        }
      }
    }
    const url = `${Url.WebPortalActualURL}create/user`;
    this.http.post<any>(url, payload).subscribe(res => {
      this.modalButtonText == 'Add' ? this.messageService.alertSuccess('Created') :
        this.messageService.alertSuccess('Updated')
      this.getUsers()
      this.clearForm()
    }, (error => {
      this.messageService.alertError('Error')
    }))
  }

  toggleView(val) {
    this.toggleVal = val
  }

}
