import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Url } from '../../../_enums/url.enums';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit, AfterViewInit {
  isLoading = true
  data = []
  displayedColumns: string[] = ['position','bundle', 'dataAmount'];
  dataSource: any = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getReports()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getReports() {
    const url = `${Url.WebPortalActualURL}all/bundleFulfillmentReport`;
    this.http.get<any>(url).subscribe(
      (res) => {
        const transformData = res?.data.sort((a, b) => (b.dataAmount) - (a.dataAmount));
        const sortedData = transformData.map((item, index) => ({
          ...item,
          position: index + 1,
          dataAmount: item.dataAmount + '%'
        }));

        sortedData.sort((a, b) => (b.dataAmount) - (a.dataAmount));
        this.dataSource = new MatTableDataSource(sortedData);
        this.dataSource.paginator = this.paginator;
        this.isLoading = false
      },
      (error) => {
        this.isLoading = false
        console.log(error);
      }
    );
  }


}
