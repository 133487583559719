import { HttpClient,HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Url } from '../../../_enums/url.enums';
import { TfaChain } from './tfa-chain';
import { MessageService } from '../messages/message.service';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
@Injectable()
export class TfaChainService {

  constructor(private http: HttpClient,private messageService: MessageService) { }

  getTfaChain() {
    return this.http.get<{ data }>(Url.ARSAPI + 'tfaList/chain', { headers: { 'Content-type': 'application/json' } });
  }

  getTfaTriggers() {
    return this.http.get<{ data }>(Url.ARSAPI + 'tfaList/trigger', { headers: { 'Content-type': 'application/json' } });
  }

  getTfaActions() {
    return this.http.get<{ data }>(Url.ARSAPI + 'tfaList/action', { headers: { 'Content-type': 'application/json' } });
  }

  createTfaChain(tfaChainDTO: {
    id, name, triggerId, actionId,
    filterParametersValues: Array<{filterId, comparatorId, value}>,
    actionParameterValues: Array<{actionParameterId, value}>
  }) {
    return this.http.post<{ data }>(Url.ARSAPI + 'tfaManage/create/', tfaChainDTO)
    .pipe(
      tap(() => this.messageService.alertSuccess('TFA Chain created!')),
      catchError((response: HttpErrorResponse) => {
        this.messageService.alertError('Error');
        return of([]);
      })
    );
  }

  editTfaChain(tfaChainDTO: {
    id, name, triggerId, actionId,
    filterParametersValues: Array<{filterId, comparatorId, value}>,
    actionParameterValues: Array<{actionParameterId, value}>
  }) {
    return this.http.post<{ data }>(Url.ARSAPI + 'tfaManage/edit/', tfaChainDTO)
    .pipe(
      tap(() => this.messageService.alertSuccess('TFA Chain edited!')),
      catchError((response: HttpErrorResponse) => {
        this.messageService.alertError('Error');
        return of([]);
      })
    );
  }

  deleteTfaChain(tfaChain: TfaChain) {
    return this.http.post<{ data }>(Url.ARSAPI + 'tfaManage/delete/', { chainId: tfaChain.id })
    .pipe(
      tap(() => this.messageService.alertSuccess(tfaChain.id ? 'Deleated TFA Chain' : 'TFA Chain deleted!')),
      catchError((response: HttpErrorResponse) => {
        this.messageService.alertError("Error");
        return of([]);
      })
    );
  }
}
