import { RoleType } from '../../_enums/role-type.enum';
import { IReseller } from '../../_interfaces/reseller.interface';
import { IServerReseller } from '../../_interfaces/server.reseller.interface';
import { Account } from './account';
import { Role } from './role';

export class Reseller extends Role implements IReseller {
  type: string;
  accounts: Account[] = [];
  distributorId: number;
  constructor(data?: IServerReseller) {
    super(data);
    this.role = RoleType.Reseller;
    if (!data) { return; }
    this.type = data.type;
    if (data.accounts && data.accounts.length) {
      for (let i = 0; i < data.accounts.length; i++) {
        this.accounts.push(new Account(data.accounts[i]));
      }
    }
  }

}
