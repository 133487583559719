import {TfaFilter} from './tfa-filter';
import {TfaAction} from './tfa-action';

export class TfaChain {
  id: String;
  name: String;
  trigger: {id: String, name: String};
  filters: TfaFilter[];
  action: TfaAction;
  filterValues: Array<{id, comparatorId, value, comparators, dataType}>;

  constructor(id: number, name: String, trigger: {id, name}, filters: TfaFilter[], action: TfaAction) {
    this.filterValues = [];
    this.filterValues.push(this.getDefaultFilterValues());
    this.id = id + '';
    this.name = name;
    this.trigger = trigger;
    this.filters = filters;
    this.action = action;
  }

  public getDefaultFilterValues(): {id: number, comparatorId, value, comparators, dataType} {
    return {id: -1, comparatorId: -1, value: '', comparators: [], dataType: ''};
  }

  public resetFilterValues(filters) {
    this.filterValues = [{id: -1, comparatorId: -1, value: '', comparators: [], dataType: ''}];
  }
}
