import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './my/dashboard/dashboard.component';
import { NetworkComponent } from './my/dashboard/network/network.component';
import { DevicesComponent } from './my/dashboard/devices/devices.component';
import { SettingsUsersComponent } from './my/dashboard/settings-users/settings-users.component';
import { SettingsArsComponent } from './my/dashboard/settings-ars/settings-ars.component';
import { SettingsBundlesComponent } from './my/dashboard/settings-bundles/settings-bundles.component';
import { SettingsDeviceGroupsComponent } from './my/dashboard/settings-device-groups/settings-device-groups.component';
import { ReportsComponent } from './my/dashboard/reports/reports.component';
import { BalanceComponent } from './my/dashboard/balance/balance.component';

const routes: Routes = [
  {path:'', component:LoginComponent},
  {
    path:'my', component:DashboardComponent,
    children:[
      {path:'network', component:NetworkComponent },
      {path:'accounts', component:NetworkComponent },
      {path:'devices', component:DevicesComponent },
      {path:'reports', component:ReportsComponent },
      {path:'balance', component:BalanceComponent },
      {path:'settings/users', component:SettingsUsersComponent },
      {path:'settings/ars', component:SettingsArsComponent },
      {path:'settings/bundles', component:SettingsBundlesComponent },
      {path:'settings/device-groups', component:SettingsDeviceGroupsComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
