<div class="container" style="max-width: 1000px;padding: 0rem 2rem;">
    <div class="row">
        <div class="mat-elevation-z8" style="width:100%;font-family:Montserrat, sans-serif;background-color: #F7F8F8;border-radius: 12px;">
            <caption class="d-flex align-items-center">
                <h2 style="margin-left: 5px;font-weight: 900;color: black;">Capacity Fulfilment Report</h2>
            </caption>
            <app-loader *ngIf="isLoading"></app-loader>
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef><b>No.</b></th>
                    <td mat-cell *matCellDef="let element"><b>{{element.position}}</b></td>
                </ng-container>
        
                <ng-container matColumnDef="bundle">
                    <th mat-header-cell *matHeaderCellDef><b>Originating Bundle</b></th>
                    <td mat-cell *matCellDef="let element"><b>{{element.bundle}}</b></td>
                </ng-container>
        
                <ng-container matColumnDef="dataAmount">
                    <th mat-header-cell *matHeaderCellDef><b>Average percentage used</b></th>
                    <td mat-cell *matCellDef="let element" style="font-weight: 500;">{{element.dataAmount}}</td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        
            <mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons style="background-color: #F7F8F8;">
            </mat-paginator>
        </div>
    </div>
</div>