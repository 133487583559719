import { Component, OnInit } from '@angular/core';
import { Url } from '../../../_enums/url.enums';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {
  isLoading = true
  balance = 0.0
  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    this.getBalance()
  }

  getBalance(){
    let url = `${Url.WebPortalActualURL}system/currentBalance`
    this.http.post<any>(url,{}).subscribe(res => {
      let bal = res.value ? res.value : 0
      this.balance = bal
      this.isLoading = false
    },(error)=>{
      this.isLoading = false
    })
  }

}
