<div class="message alert-success animate" [ngClass]="{'fade-in-down': messageService.showSuccessMessage, 'fade-out-up': messageService.showSuccessMessage === false}"
  *ngIf="messageService.successMessage">
  <div class="message-text">{{messageService.successMessage}}</div>
  <div class="close-message" (click)="messageService.closeSuccessMessage()">
    <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
  </div>
</div>
<div class="message alert-error animate" [ngClass]="{'fade-in-down': messageService.showErrorMessage, 'fade-out-up': messageService.showErrorMessage === false}"
  *ngIf="messageService.errorMessage">
  <div class="message-text">{{messageService.errorMessage}}</div>
  <div class="close-message" (click)="messageService.closeErrorMessage()">
    <mat-icon style="font-size: 20px;">highlight_off</mat-icon>
  </div>
</div>
